.home {
    overflow-x: hidden!important;
    .big-post-highlight {
        margin: 0 0 60px 0!important;
        position: relative!important;

		@media(max-width: 1210px) {
			.flickity-prev-next-button{
				margin-top: 0 !important;
			}
		}

        &:after {
            content: ''!important;
            border-bottom: 2px solid #a7a8a0!important;
            display: block!important;
            position: absolute!important;
            height: 530px!important;
            left: 0!important;
            width: 100%!important;
            pointer-events: none!important;
            z-index: 0!important;
            bottom: 0!important;
            @include breakpoint(large) {
                width: calc( 100% + ( ( 100vw - 100% ) / 2) ) !important;
                bottom: -30px!important;
                background-image: url(/wp-content/themes/perseuabramo/assets/img/grafismo-slider.png)!important;
                background-repeat: no-repeat!important;
                background-position: bottom -1px right 0!important;
            }
			@media(max-width: 1250px) {
				background-size: 10%;
			}

        }

        .post-card {
            padding: 0!important;
            position: relative;
            * {z-index: 1!important; position: relative!important;}
            a {
                @extend .row;
            }
            .post-image {
                @extend .column;
                @extend .smaller-12;
                @extend .large-8;
            }
            .post-info {
                @extend .column;
                @extend .smaller-12;
                @extend .large-3;
                @include breakpoint(large up) {
                    max-width: none!important;
                    flex-grow: 1!important;
                }
            }
            .post-category {
                margin: 0 0 30px!important;
            }
            .post-title {
                color: $dark-green!important;
                font-size: 30px!important;
                font-family: $body-font-family!important;
                @include link-underline($dark-green,$dark-green,$dark-green);
            }
            .post-description {
                color: $dark-gray!important;
                font-size: 18px!important;
            }
            .post-authors {
                color: $dark-gray!important;
                font-size: 14px!important;
                margin-top: 30px!important;
            }
        }
    }
    .small-post-highlight {
        border-bottom: 2px solid lightgray!important;
        margin-bottom: 50px!important;
    }
}
