//===============================================
// Home
//===============================================

.home {


#app{
	.content-wrapper{
		.et-boc{
			.et_builder_outer_content{
				.et-l--post{
					.et_builder_inner_content{
						.estantes-section{
							.et_pb_row{
								.et_pb_column{
									.et_pb_module{
										.et_pb_module_inner{
											div{
												.big-post-highlight{
													.flickity-viewport{
														.flickity-slider{
															.post-card{
																header{
																	width: 100% !important;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

    .et_pb_row {
        padding: 25px 0!important;
        border-top: 0!important;
    }

    .big-post-highlight {
        margin-bottom: 40px!important;
    }

    .et_pb_column_1_4{
        .small-post-highlight .post-card{
            flex: 0 0 100% !important;
            max-width: 100% !important;
            .post-category {}
            .post-title {font-size: 14px!important;}
            .post-author {font-size: 14px!important; color: #403f3c!important;}
        }
    }

    .et_pb_column_3_4 {
        .small-post-highlight {
            margin-bottom: 0!important;
            padding-bottom: 20px!important;
            .post-card {
                @extend .smaller-12;
                @extend .large-4;
                height: auto !important;
            }
        }
    }

    .estantes-section {
        margin-bottom: 0!important;
    }

    .highlight-multimidia,
    .highlight-cadernos {
        padding-bottom: 0!important;
    }
    .colunistas-highlight .item + .item {
        border-top: 0!important;
    }

}

.highlight-cadernos {

    .highlight-title{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include breakpoint(small down) {
            justify-content: center;
        }
        .logo{
            background-image: url(/wp-content/themes/teoriaedebate/assets/img/logo-cadernos-ted-color.svg);
            background-position: left center;
            background-size: 100% auto;
            background-repeat: no-repeat;
            width: 320px;
            z-index: 0;
            margin-left: 15px;
            height: 100px;
            text-indent: -500px;
        }
        span:nth-child(2){
            font-size: 12px;
            color: #666;
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #ccc;
            text-transform: uppercase;
        }
    }
}



// .home .flickity-slider .post-card header{
// 	width: 100% !important;
// }

.posts-row {
    .et_pb_column {
        @extend .smaller-12;
        @extend .large-6;
    }
}

.colunistas-row {
    .et_pb_column_1_2 {
        @extend .smaller-12;
        @extend .large-6;
    }
    .et_pb_column_1_4 {
        @extend .smaller-12;
        @extend .medium-6;
        @extend .large-3;
    }
}

.big-post-highlight .flickity-prev-next-button {
    background: transparent!important;
}
.post-card .post-image {
    background-color: #f2ebec;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: initial;
    position: relative;
}
.post-card .post-description {
    color: #2a2426;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
}
.post-card .post-authors {
    color: #948f8f;
    font-size: 12px;
    margin: 4px 0 0;
}
.post-card .post-image-credit {
    color: #948f8f;
    font-size: 12px;
    margin: 4px 0 0;
}
#app .et_pb_section_0 .et_pb_column_4_4 .flickity-viewport {
    width:100%;
}
