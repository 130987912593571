//===============================================
// Footer
//===============================================
.footer-logos{
	margin-left: -100px;
	margin-top: 10px;
}

.logo-instituto-lula {
	display: none;
}

//===============================================
// Pre-Footer
//===============================================

	.ted--pre-footer {
		position: relative!important;
		padding-top: 15px!important;
		padding-bottom: 50px!important;
		margin-bottom: -50px!important;
		overflow: hidden!important;
		background: linear-gradient(
			to bottom,
			#EDEDE8 0px,
			#FEFEFE 80px,
			#DADAD0 430px)!important;

		* {
			z-index: 9!important;
		}

		&:after {
			content: ""!important;
			background-image: url(/wp-content/themes/teoriaedebate/assets/img/pre-footer__detail.png)!important;
			background-repeat: no-repeat!important;
			background-size: auto 100%!important;
			background-position: bottom center!important;
			position: absolute!important;
			bottom: 0!important;
			left: 50%!important;
			width: 756px!important;
			height: calc( 100% - 150px )!important;
			max-height: 445px!important;
			z-index: 0!important;

			display: none!important;
			@include breakpoint(large) {
				display: block!important;
			}
		}

		.editorias--wrap {
			display: block!important;
			position: relative!important;
		}

		.menu-editorias-container {
			border: 0!important;
		}

		.pre-footer-menu--wrap {
			margin-top: 15px!important;
			padding-top: 30px!important;
			border-top: 1px solid #DCDCDA!important;
		}


		.edicao {
			width: 100%!important;
			margin-bottom: 20px!important;
			text-align: center!important;
			@include breakpoint(small) {
				text-align: left!important;
			}
			@include breakpoint(xxlarge) {
				text-align: right!important;
				margin-bottom: 0!important;
			}
			.logo-ted {
				background-image: url(/wp-content/themes/teoriaedebate/assets/img/logo-td.png)!important;
			    background-repeat: no-repeat!important;
			    height: 45px!important;
			    width: 275px!important;
			    margin: 0 auto!important;
			    margin-bottom: 20px!important;
				@include breakpoint(small) {
					margin: 0 0 20px 0!important;
				}

			    span {
		    	    position: absolute !important;
				    width: 1px!important;
				    height: 1px!important;
				    overflow: hidden!important;
				    clip: rect(0, 0, 0, 0)!important;
			    }
			}
			p {
				font-size: 14px!important;
				margin: 0!important;
				color: #565552!important;
				a {
					color: #BFC0B9!important;
				}
			}
		}

		#pre-footer-menu-ted, #pre-footer-menu-ted ul:not(.sub-menu, .children) {
			display: flex!important;
			flex-direction: row!important;
			flex-wrap: wrap!important;
			margin-left: 0!important;
			& > li.page_item,
			& > li.menu-item {
				margin-bottom: 5px!important;
				list-style-type: none!important;
				flex: 0 0 100%!important;
				text-align: center!important;
				@include breakpoint(small) {
					flex: 0 0 50%!important;
					text-align: left!important;
				}
				@include breakpoint(medium) {
					flex: 0 0 33.3%!important;
				}
				& > a {
					text-transform: uppercase!important;
					font-weight: 300!important;
					font-size: 13px!important;
					color: #BB1B2E!important;
				}
			}
			ul.sub-menu, ul.children {
				margin: 10px 0 0 0!important;
				padding: 0!important;
				li.page_item,
				li.menu-item {
					list-style-type: none!important;
					padding-right: 20px!important;
					a {
						font-size: 12px!important;
						color: #565552!important;
						-webkit-transition: all 150ms ease-in-out!important;
						-moz-transition: all 150ms ease-in-out!important;
						-ms-transition: all 150ms ease-in-out!important;
						-o-transition: all 150ms ease-in-out!important;
						transition: all 150ms ease-in-out!important;
						&:hover {
							color: darken(#565552,20%)!important;
						}
					}
				}
			}
		}

		.creative-commons {
			border-top: 2px solid #A8A9A1!important;
			margin-top: 15px!important;
			padding-top: 20px!important;
			h6 {
				font-size: 13px!important;
				font-weight: bold!important;
				color: #565552!important;
				margin-bottom: 3px!important;
			}
			p {
				color: #A8A9A1!important;
				font-size: 13px!important;
			}
			.creative-commons--license {
				margin-left: 15px!important;
				@include breakpoint(xxlarge) {
					margin-left: 0!important;
				}
				img {
					margin-top: 3px!important;
				}
			}
		}
	}
