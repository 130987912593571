.highlight {

}

body:not(.single-colunas) .highlight-title {
    color: $dark-red !important;
    font-weight: 300 !important;
}
body.single-colunas .highlight-title {
    color:$medium-gray !important;
}
@import 'agenda';
@import 'colunistas';
@import 'estantes';
@import 'home-posts';
@import 'post-content';
@import 'post-highlights';
@import 'post-small-list';
@import 'teoriaedebate';