.home .colunistas-highlight .item + .item{
	margin-top: 0px!important;
    padding-top: 0px!important;
}

.et_pb_column.et_pb_column_1_4 .colunistas-highlight{
    .item.row{
        width: 100%!important;    
    	border-top: none!important;

        .column{ width: 100%!important; }

        a{
        	display: flex!important;
    		justify-content: center!important;
        }

        .name,.excerpt { width: 100%!important; }
    }
}


.et_pb_column_1_2 .colunistas-highlight {
    .item{
        flex: 0 0 100%!important;
        max-width: 100%!important;
        width: 100%!important;
    }
}

.colunistas-highlight {
    .item{
        flex: 0 0 50%!important;
        max-width: 50%!important;
        width: 50%!important;
        float: left!important;
        margin-bottom: 20px!important;
        
        & + .item { padding-top: 0px!important; margin-top: 0px!important;  }
   }
}