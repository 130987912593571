@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lucida Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  line-height: 0;
  color: #948f8f;
}

@media print, screen and (min-width: 30em) {
  h1 {
    font-size: 1.5rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 1.25rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-size: 1.1875rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h5 {
    font-size: 1.0625rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  h6 {
    font-size: 1rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.9375rem;
  }
  h4 {
    font-size: 1.5625rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
}
a {
  line-height: inherit;
  color: #403f3c;
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #373634;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 78.125rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #948f8f;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #948f8f;
}
blockquote, blockquote p {
  line-height: 1.6;
  color: #403f3c;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #403f3c;
}
cite:before {
  content: "— ";
}

abbr {
  border-bottom: 1px dotted #000;
  color: #403f3c;
  cursor: help;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #948f8f;
  background-color: #a7a8a0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #a7a8a0;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #403f3c;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

.no-bullet {
  margin-left: 0;
  list-style: none;
}

.smaller-text-left {
  text-align: left;
}

.smaller-text-right {
  text-align: right;
}

.smaller-text-center {
  text-align: center;
}

.smaller-text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 30em) {
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 68.75em) {
  .xlarge-text-left {
    text-align: left;
  }
  .xlarge-text-right {
    text-align: right;
  }
  .xlarge-text-center {
    text-align: center;
  }
  .xlarge-text-justify {
    text-align: justify;
  }
}
@media screen and (min-width: 75em) {
  .xxlarge-text-left {
    text-align: left;
  }
  .xxlarge-text-right {
    text-align: right;
  }
  .xxlarge-text-center {
    text-align: center;
  }
  .xxlarge-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #403f3c;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #948f8f;
  border-radius: 0;
  background-color: #f3eeee;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #403f3c;
  background-color: #f3eeee;
  box-shadow: 0 0 5px #948f8f;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #948f8f;
}
input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #a7a8a0;
  cursor: not-allowed;
}

[type=submit],
[type=button] {
  appearance: none;
  border-radius: 0;
}

input[type=search] {
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 1rem;
}

[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
[type=checkbox] + label[for],
[type=radio] + label[for] {
  cursor: pointer;
}

label > [type=checkbox],
label > [type=radio] {
  margin-right: 0.5rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000;
}
label.middle {
  margin: 0 0 1rem;
  padding: 0.5625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch;
}
.input-group > :first-child {
  border-radius: 0 0 0 0;
}
.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #948f8f;
  background: #a7a8a0;
  color: #000;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  flex: 0 0 auto;
}
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #948f8f;
}
.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
  background: #f3eeee;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #948f8f;
  border-radius: 0;
  background-color: #f3eeee;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #000;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%2864, 63, 60%29"></polygon></svg>');
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select:focus {
  outline: none;
  border: 1px solid #403f3c;
  background-color: #f3eeee;
  box-shadow: 0 0 5px #948f8f;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  background-color: #a7a8a0;
  cursor: not-allowed;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #efdedc;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37;
}
.form-error.is-visible {
  display: block;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #f3eeee;
}
[data-whatinput=mouse] .button {
  outline: 0;
}
.button:hover, .button:focus {
  background-color: #14679e;
  color: #f3eeee;
}
.button.tiny {
  font-size: 0.6rem;
}
.button.small {
  font-size: 0.75rem;
}
.button.large {
  font-size: 1.25rem;
}
.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.button.primary {
  background-color: #1779ba;
  color: #f3eeee;
}
.button.primary:hover, .button.primary:focus {
  background-color: #126195;
  color: #f3eeee;
}
.button.secondary {
  background-color: #767676;
  color: #f3eeee;
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #f3eeee;
}
.button.success {
  background-color: #3adb76;
  color: #000;
}
.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #000;
}
.button.warning {
  background-color: #ffae00;
  color: #000;
}
.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #000;
}
.button.alert {
  background-color: #cc4b37;
  color: #f3eeee;
}
.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #f3eeee;
}
.button.hollow {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}
.button.hollow:hover, .button.hollow:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.hollow.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}
.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}
.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676;
}
.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}
.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #1779ba;
  color: #f3eeee;
}
.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #1779ba;
  color: #f3eeee;
}
.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #f3eeee;
}
.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #000;
}
.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #000;
}
.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #f3eeee;
}
.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.4em;
  content: "";
  border-bottom-width: 0;
  border-color: #f3eeee transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}
.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
.button-group::before, .button-group::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.button-group::after {
  clear: both;
}
.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}
.button-group .button:last-child {
  margin-right: 0;
}
.button-group.tiny .button {
  font-size: 0.6rem;
}
.button-group.small .button {
  font-size: 0.75rem;
}
.button-group.large .button {
  font-size: 1.25rem;
}
.button-group.expanded .button {
  flex: 1 1 0px;
}
.button-group.primary .button {
  background-color: #1779ba;
  color: #f3eeee;
}
.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #126195;
  color: #f3eeee;
}
.button-group.secondary .button {
  background-color: #767676;
  color: #f3eeee;
}
.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #5e5e5e;
  color: #f3eeee;
}
.button-group.success .button {
  background-color: #3adb76;
  color: #000;
}
.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #000;
}
.button-group.warning .button {
  background-color: #ffae00;
  color: #000;
}
.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #000;
}
.button-group.alert .button {
  background-color: #cc4b37;
  color: #f3eeee;
}
.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #f3eeee;
}
.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}
.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}
.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 30em) and (max-width: 39.99875em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  background-color: #fdfcfc;
  color: #403f3c;
}
.callout > :first-child {
  margin-top: 0;
}
.callout > :last-child {
  margin-bottom: 0;
}
.callout.primary {
  background-color: #d7ecfa;
  color: #403f3c;
}
.callout.secondary {
  background-color: #eaeaea;
  color: #403f3c;
}
.callout.success {
  background-color: #e1faea;
  color: #403f3c;
}
.callout.warning {
  background-color: #fff3d9;
  color: #403f3c;
}
.callout.alert {
  background-color: #f7e4e1;
  color: #403f3c;
}
.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}
.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #a7a8a0;
  border-radius: 0;
  background: #f3eeee;
  box-shadow: none;
  overflow: hidden;
  color: #403f3c;
}
.card > :last-child {
  margin-bottom: 0;
}

.card-divider {
  flex: 0 1 auto;
  padding: 1rem;
  background: #a7a8a0;
}
.card-divider > :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}
.card-section > :last-child {
  margin-bottom: 0;
}

.close-button {
  position: absolute;
  color: #403f3c;
  cursor: pointer;
}
[data-whatinput=mouse] .close-button {
  outline: 0;
}
.close-button:hover, .close-button:focus {
  color: #000;
}
.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}
.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}
.menu > li {
  flex: 0 0 auto;
}
[data-whatinput=mouse] .menu > li {
  outline: 0;
}
.menu > li > a {
  display: block;
  padding: 0.7rem 1rem;
  line-height: 1;
}
.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}
.menu > li > a {
  display: flex;
}
.menu > li > a {
  flex-flow: row nowrap;
}
.menu > li > a img,
.menu > li > a i,
.menu > li > a svg {
  margin-right: 0.25rem;
}
.menu, .menu.horizontal {
  flex-wrap: nowrap;
}
.menu > li, .menu.horizontal > li {
  flex: 0 0 auto;
}
.menu.expanded > li {
  flex: 1 1 0px;
}
.menu.expanded > li:first-child:last-child {
  width: 100%;
}
.menu.vertical {
  flex-wrap: wrap;
}
.menu.vertical > li {
  flex: 0 0 100%;
  max-width: 100%;
}
.menu.vertical > li > a {
  justify-content: flex-start;
  align-items: flex-start;
}
.menu.smaller-horizontal {
  flex-wrap: nowrap;
}
.menu.smaller-horizontal > li {
  flex: 0 0 auto;
}
.menu.smaller-expanded > li {
  flex: 1 1 0px;
}
.menu.smaller-expanded > li:first-child:last-child {
  width: 100%;
}
.menu.smaller-vertical {
  flex-wrap: wrap;
}
.menu.smaller-vertical > li {
  flex: 0 0 100%;
  max-width: 100%;
}
.menu.smaller-vertical > li > a {
  justify-content: flex-start;
  align-items: flex-start;
}
@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: nowrap;
  }
  .menu.medium-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.medium-expanded > li {
    flex: 1 1 0px;
  }
  .menu.medium-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.medium-vertical {
    flex-wrap: wrap;
  }
  .menu.medium-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.medium-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: nowrap;
  }
  .menu.large-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.large-expanded > li {
    flex: 1 1 0px;
  }
  .menu.large-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.large-vertical {
    flex-wrap: wrap;
  }
  .menu.large-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.large-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (min-width: 68.75em) {
  .menu.xlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (min-width: 75em) {
  .menu.xxlarge-horizontal {
    flex-wrap: nowrap;
  }
  .menu.xxlarge-horizontal > li {
    flex: 0 0 auto;
  }
  .menu.xxlarge-expanded > li {
    flex: 1 1 0px;
  }
  .menu.xxlarge-expanded > li:first-child:last-child {
    width: 100%;
  }
  .menu.xxlarge-vertical {
    flex-wrap: wrap;
  }
  .menu.xxlarge-vertical > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu.xxlarge-vertical > li > a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.menu.simple li {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
}
.menu.simple a {
  padding: 0;
}
.menu.simple li {
  margin-left: 0;
  margin-right: 1rem;
}
.menu.simple.align-right li {
  margin-right: 0;
  margin-left: 1rem;
}
.menu.align-right {
  justify-content: flex-end;
}
.menu.icon-top > li > a {
  flex-flow: column nowrap;
}
.menu.icon-top > li > a img,
.menu.icon-top > li > a i,
.menu.icon-top > li > a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu.icon-top.vertical a > span {
  margin: auto;
}
.menu.nested {
  margin-left: 1rem;
}
.menu .active > a {
  background: #1779ba;
  color: #f3eeee;
}
.menu.menu-bordered li {
  border: 1px solid #a7a8a0;
}
.menu.menu-bordered li:not(:first-child) {
  border-top: 0;
}
.menu.menu-hover li:hover {
  background-color: #a7a8a0;
}

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered {
  text-align: center;
}
.menu-centered > .menu {
  display: inline-block;
  vertical-align: top;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #f3eeee;
}
.label.primary {
  background: #1779ba;
  color: #f3eeee;
}
.label.secondary {
  background: #767676;
  color: #f3eeee;
}
.label.success {
  background: #3adb76;
  color: #000;
}
.label.warning {
  background: #ffae00;
  color: #000;
}
.label.alert {
  background: #cc4b37;
  color: #f3eeee;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}
.media-object img {
  max-width: none;
}
@media print, screen and (min-width: 30em) and (max-width: 39.99875em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}
@media print, screen and (min-width: 30em) and (max-width: 39.99875em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}
.media-object-section:first-child {
  padding-right: 1rem;
}
.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}
.media-object-section > :last-child {
  margin-bottom: 0;
}
.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(243, 238, 238, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}
.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}
.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #a7a8a0;
}
[data-whatinput=mouse] .off-canvas {
  outline: 0;
}
.off-canvas.is-transition-overlap {
  z-index: 10;
}
.off-canvas.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}
.off-canvas.is-open {
  transform: translate(0, 0);
}

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #a7a8a0;
}
[data-whatinput=mouse] .off-canvas-absolute {
  outline: 0;
}
.off-canvas-absolute.is-transition-overlap {
  z-index: 10;
}
.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}
.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  transform: translateX(-250px);
  overflow-y: auto;
}
.position-left.is-open ~ .off-canvas-content {
  transform: translateX(250px);
}
.position-left.is-transition-push::after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  content: " ";
}
.position-left.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.position-right {
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transform: translateX(250px);
  overflow-y: auto;
}
.position-right.is-open ~ .off-canvas-content {
  transform: translateX(-250px);
}
.position-right.is-transition-push::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  content: " ";
}
.position-right.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto;
}
.position-top.is-open ~ .off-canvas-content {
  transform: translateY(250px);
}
.position-top.is-transition-push::after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  content: " ";
}
.position-top.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto;
}
.position-bottom.is-open ~ .off-canvas-content {
  transform: translateY(-250px);
}
.position-bottom.is-transition-push::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  content: " ";
}
.position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
  transform: none;
}

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}

.position-left.reveal-for-smaller {
  transform: none;
  z-index: 1;
}
.position-left.reveal-for-smaller ~ .off-canvas-content {
  margin-left: 250px;
}

.position-right.reveal-for-smaller {
  transform: none;
  z-index: 1;
}
.position-right.reveal-for-smaller ~ .off-canvas-content {
  margin-right: 250px;
}

.position-top.reveal-for-smaller {
  transform: none;
  z-index: 1;
}
.position-top.reveal-for-smaller ~ .off-canvas-content {
  margin-top: 250px;
}

.position-bottom.reveal-for-smaller {
  transform: none;
  z-index: 1;
}
.position-bottom.reveal-for-smaller ~ .off-canvas-content {
  margin-bottom: 250px;
}

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media screen and (min-width: 68.75em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-xlarge ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-xlarge ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-xlarge ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media screen and (min-width: 75em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-left.reveal-for-xxlarge ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-right.reveal-for-xxlarge ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-top.reveal-for-xxlarge ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 1;
  }
  .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}
.pagination::before, .pagination::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.pagination::after {
  clear: both;
}
.pagination li {
  margin-right: 0.0625rem;
  border-radius: 0;
  font-size: 0.875rem;
  display: none;
}
.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}
@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}
.pagination a,
.pagination button {
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
  color: #000;
}
.pagination a:hover,
.pagination button:hover {
  background: #a7a8a0;
}
.pagination .current {
  padding: 0.1875rem 0.625rem;
  background: #1779ba;
  color: #f3eeee;
  cursor: default;
}
.pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #948f8f;
  cursor: not-allowed;
}
.pagination .disabled:hover {
  background: transparent;
}
.pagination .ellipsis::after {
  padding: 0.1875rem 0.625rem;
  content: "…";
  color: #000;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: "«";
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: "»";
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #a7a8a0;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #948f8f;
  transition: all 0.2s ease-in-out;
}
.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}
[data-whatinput=mouse] .slider-handle {
  outline: 0;
}
.slider-handle:hover {
  background-color: #14679e;
}
.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}
.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}
.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #948f8f;
  border-radius: 0;
  background-color: #f3eeee;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}
[data-whatinput=mouse] .reveal {
  outline: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}
.reveal .column, .reveal .home .big-post-highlight .post-card .post-info, .home .big-post-highlight .post-card .reveal .post-info, .reveal .home .big-post-highlight .post-card .post-image, .home .big-post-highlight .post-card .reveal .post-image,
.reveal .columns {
  min-width: 0;
}
.reveal > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 78.125rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}
.reveal.collapse {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 78.125rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 78.125rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 78.125rem;
  }
}
.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}
@media print, screen and (min-width: 30em) and (max-width: 39.99875em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}
.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #f3eeee;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #948f8f;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}
input + .switch-paddle {
  margin: 0;
}
.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #f3eeee;
  transition: all 0.25s ease-out;
  content: "";
}
input:checked ~ .switch-paddle {
  background: #1779ba;
}
input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}
[data-whatinput=mouse] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-inactive, .switch-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}
input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}
input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}
.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.625rem;
}
.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}
.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}
.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.75rem;
}
.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}
.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1rem;
}
.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}
.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}
thead,
tbody,
tfoot {
  border: 1px solid #e9e0e0;
  background-color: #f3eeee;
}

caption {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
}

thead {
  background: #eee7e7;
  color: #403f3c;
}

tfoot {
  background: #e9e0e0;
  color: #403f3c;
}

thead tr,
tfoot tr {
  background: transparent;
}
thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: bold;
  text-align: left;
}

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #e9e0e0;
}

table.unstriped tbody {
  background-color: #f3eeee;
}
table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #e9e0e0;
  background-color: #f3eeee;
}

@media print, screen and (max-width: 63.99875em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #eae1e1;
}
table.hover tfoot tr:hover {
  background-color: #e5dada;
}
table.hover tbody tr:hover {
  background-color: #efe8e8;
}
table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #e4d9d9;
}

.table-scroll {
  overflow-x: auto;
}
.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid #a7a8a0;
  background: #f3eeee;
  list-style-type: none;
}
.tabs::before, .tabs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}
.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #1779ba;
}
.tabs.primary > li > a {
  color: #f3eeee;
}
.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #1673b1;
}

.tabs-title {
  float: left;
}
.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba;
}
.tabs-title > a:hover {
  background: #f3eeee;
  color: #1468a0;
}
.tabs-title > a:focus, .tabs-title > a[aria-selected=true] {
  background: #a7a8a0;
  color: #1779ba;
}

.tabs-content {
  border: 1px solid #a7a8a0;
  border-top: 0;
  background: #f3eeee;
  color: #403f3c;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #a7a8a0;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}
.tabs-panel[aria-hidden=false] {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #f3eeee;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}
a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5);
}
a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #000;
  color: #f3eeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #403f3c;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #000;
  font-size: 80%;
  color: #f3eeee;
}
.tooltip::before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.75rem;
  content: "";
  border-top-width: 0;
  border-color: transparent transparent #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.75rem;
  content: "";
  border-bottom-width: 0;
  border-color: #000 transparent transparent;
  top: 100%;
  bottom: auto;
}
.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.75rem;
  content: "";
  border-right-width: 0;
  border-color: transparent transparent transparent #000;
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translateY(-50%);
}
.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.75rem;
  content: "";
  border-left-width: 0;
  border-color: transparent #000 transparent transparent;
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
}
.top-bar,
.top-bar ul {
  background-color: #a7a8a0;
}
.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}
.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}
.top-bar input.button {
  width: auto;
}
.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}
@media print, screen and (min-width: 40em) {
  .top-bar {
    flex-wrap: nowrap;
  }
  .top-bar .top-bar-left {
    flex: 1 1 auto;
  }
  .top-bar .top-bar-right {
    flex: 0 1 auto;
  }
}
@media screen and (max-width: 29.99875em) {
  .top-bar.stacked-for-smaller {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-smaller .top-bar-left,
  .top-bar.stacked-for-smaller .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media print, screen and (max-width: 63.99875em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media print, screen and (max-width: 68.74875em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media print, screen and (max-width: 74.99875em) {
  .top-bar.stacked-for-xlarge {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-xlarge .top-bar-left,
  .top-bar.stacked-for-xlarge .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.top-bar.stacked-for-xxlarge {
  flex-wrap: wrap;
}
.top-bar.stacked-for-xxlarge .top-bar-left,
.top-bar.stacked-for-xxlarge .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

.hide-for-smaller {
  display: none !important;
}

.show-for-smaller {
  display: none !important;
}

@media screen and (max-width: 29.99875em) {
  .hide-for-smaller-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 30em) {
  .show-for-smaller-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 30em) and (max-width: 39.99875em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 29.9375em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) and (max-width: 68.74875em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 68.75em) {
  .show-for-large-only {
    display: none !important;
  }
}

@media screen and (min-width: 68.75em) {
  .hide-for-xlarge {
    display: none !important;
  }
}

@media screen and (max-width: 68.6875em) {
  .show-for-xlarge {
    display: none !important;
  }
}

@media screen and (min-width: 68.75em) and (max-width: 74.99875em) {
  .hide-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 68.6875em), screen and (min-width: 75em) {
  .show-for-xlarge-only {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-xxlarge {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-xxlarge {
    display: none !important;
  }
}

@media screen and (min-width: 75em) {
  .hide-for-xxlarge-only {
    display: none !important;
  }
}

@media screen and (max-width: 74.9375em) {
  .show-for-xxlarge-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.clearfix::after {
  clear: both;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

@media print, screen and (min-width: 30em) {
  .small-order-1 {
    order: 1;
  }
  .small-order-2 {
    order: 2;
  }
  .small-order-3 {
    order: 3;
  }
  .small-order-4 {
    order: 4;
  }
  .small-order-5 {
    order: 5;
  }
  .small-order-6 {
    order: 6;
  }
}
.smaller-order-1 {
  order: 1;
}

.smaller-order-2 {
  order: 2;
}

.smaller-order-3 {
  order: 3;
}

.smaller-order-4 {
  order: 4;
}

.smaller-order-5 {
  order: 5;
}

.smaller-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}
@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}
@media screen and (min-width: 68.75em) {
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-order-6 {
    order: 6;
  }
}
@media screen and (min-width: 75em) {
  .xxlarge-order-1 {
    order: 1;
  }
  .xxlarge-order-2 {
    order: 2;
  }
  .xxlarge-order-3 {
    order: 3;
  }
  .xxlarge-order-4 {
    order: 4;
  }
  .xxlarge-order-5 {
    order: 5;
  }
  .xxlarge-order-6 {
    order: 6;
  }
}
.row, .home .big-post-highlight .post-card a {
  max-width: 78.125rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row, .home .big-post-highlight .post-card a .row, .row .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row a, .home .big-post-highlight .post-card a a {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}
@media print, screen and (min-width: 30em) {
  .row .row, .home .big-post-highlight .post-card a .row, .row .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row a, .home .big-post-highlight .post-card a a {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .row .row, .home .big-post-highlight .post-card a .row, .row .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row a, .home .big-post-highlight .post-card a a {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .row .row, .home .big-post-highlight .post-card a .row, .row .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row a, .home .big-post-highlight .post-card a a {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 68.75em) {
  .row .row, .home .big-post-highlight .post-card a .row, .row .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row a, .home .big-post-highlight .post-card a a {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 75em) {
  .row .row, .home .big-post-highlight .post-card a .row, .row .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row a, .home .big-post-highlight .post-card a a {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse, .home .big-post-highlight .post-card a .row.collapse, .row .home .big-post-highlight .post-card a.collapse, .home .big-post-highlight .post-card .row a.collapse, .home .big-post-highlight .post-card a a.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded, .home .big-post-highlight .post-card a.expanded {
  max-width: none;
}
.row:not(.expanded) .row, .home .big-post-highlight .post-card a:not(.expanded) .row, .row:not(.expanded) .home .big-post-highlight .post-card a, .home .big-post-highlight .post-card .row:not(.expanded) a, .home .big-post-highlight .post-card a:not(.expanded) a {
  max-width: none;
}
.row.collapse > .column, .home .big-post-highlight .post-card .row.collapse > .post-info, .home .big-post-highlight .post-card .row.collapse > .post-image, .home .big-post-highlight .post-card a.collapse > .column, .home .big-post-highlight .post-card a.collapse > .post-info, .home .big-post-highlight .post-card a.collapse > .post-image, .row.collapse > .columns, .home .big-post-highlight .post-card a.collapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.row.is-collapse-child, .home .big-post-highlight .post-card a.is-collapse-child, .row.collapse > .column > .row, .home .big-post-highlight .post-card .row.collapse > .post-info > .row, .home .big-post-highlight .post-card .row.collapse > .post-image > .row, .home .big-post-highlight .post-card a.collapse > .column > .row, .home .big-post-highlight .post-card a.collapse > .post-info > .row, .home .big-post-highlight .post-card a.collapse > .post-image > .row, .home .big-post-highlight .post-card .row.collapse > .column > a, .home .big-post-highlight .post-card .row.collapse > .post-info > a, .home .big-post-highlight .post-card .row.collapse > .post-image > a, .home .big-post-highlight .post-card a.collapse > .column > a, .home .big-post-highlight .post-card a.collapse > .post-info > a, .home .big-post-highlight .post-card a.collapse > .post-image > a, .row.collapse > .columns > .row, .home .big-post-highlight .post-card a.collapse > .columns > .row, .home .big-post-highlight .post-card .row.collapse > .columns > a, .home .big-post-highlight .post-card a.collapse > .columns > a {
  margin-right: 0;
  margin-left: 0;
}

.column, .home .big-post-highlight .post-card .post-info, .home .big-post-highlight .post-card .post-image, .columns {
  flex: 1 1 0px;
  min-width: initial;
}
@media print, screen and (min-width: 30em) {
  .column, .home .big-post-highlight .post-card .post-info, .home .big-post-highlight .post-card .post-image, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 40em) {
  .column, .home .big-post-highlight .post-card .post-info, .home .big-post-highlight .post-card .post-image, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .home .big-post-highlight .post-card .row.row.post-info, .home .big-post-highlight .post-card .row.row.post-image, .home .big-post-highlight .post-card a.column, .home .big-post-highlight .post-card a.post-info, .home .big-post-highlight .post-card a.post-image, .row.row.columns, .home .big-post-highlight .post-card a.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .home .big-post-highlight .post-card .row.row.post-info, .home .big-post-highlight .post-card .row .row.row.post-info, .row .home .big-post-highlight .post-card .row.row.post-image, .home .big-post-highlight .post-card .row .row.row.post-image, .home .big-post-highlight .post-card a .column.row.row, .home .big-post-highlight .post-card a .row.row.post-info, .home .big-post-highlight .post-card a .row.row.post-image, .row .home .big-post-highlight .post-card a.column, .row .home .big-post-highlight .post-card a.post-info, .row .home .big-post-highlight .post-card a.post-image, .home .big-post-highlight .post-card .row a.column, .home .big-post-highlight .post-card .row a.post-info, .home .big-post-highlight .post-card .row a.post-image, .home .big-post-highlight .post-card a a.column, .home .big-post-highlight .post-card a a.post-info, .home .big-post-highlight .post-card a a.post-image, .row .row.row.columns, .home .big-post-highlight .post-card a .row.row.columns, .row .home .big-post-highlight .post-card a.columns, .home .big-post-highlight .post-card .row a.columns, .home .big-post-highlight .post-card a a.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 30em) {
  .small-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .small-offset-0 {
    margin-left: 0%;
  }
  .small-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .small-offset-1 {
    margin-left: 8.3333333333%;
  }
  .small-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .small-offset-2 {
    margin-left: 16.6666666667%;
  }
  .small-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .small-offset-3 {
    margin-left: 25%;
  }
  .small-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .small-offset-4 {
    margin-left: 33.3333333333%;
  }
  .small-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .small-offset-5 {
    margin-left: 41.6666666667%;
  }
  .small-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .small-offset-6 {
    margin-left: 50%;
  }
  .small-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .small-offset-7 {
    margin-left: 58.3333333333%;
  }
  .small-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .small-offset-8 {
    margin-left: 66.6666666667%;
  }
  .small-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .small-offset-9 {
    margin-left: 75%;
  }
  .small-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .small-offset-10 {
    margin-left: 83.3333333333%;
  }
  .small-12, .et_pb_column_1_4 .small-post-highlight .post-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .small-offset-11 {
    margin-left: 91.6666666667%;
  }
  .small-order-1 {
    order: 1;
  }
  .small-order-2 {
    order: 2;
  }
  .small-order-3 {
    order: 3;
  }
  .small-order-4 {
    order: 4;
  }
  .small-order-5 {
    order: 5;
  }
  .small-order-6 {
    order: 6;
  }
  .small-up-1 {
    flex-wrap: wrap;
  }
  .small-up-1 > .column, .home .big-post-highlight .post-card .small-up-1 > .post-info, .home .big-post-highlight .post-card .small-up-1 > .post-image, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .small-up-2 {
    flex-wrap: wrap;
  }
  .small-up-2 > .column, .home .big-post-highlight .post-card .small-up-2 > .post-info, .home .big-post-highlight .post-card .small-up-2 > .post-image, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .small-up-3 {
    flex-wrap: wrap;
  }
  .small-up-3 > .column, .home .big-post-highlight .post-card .small-up-3 > .post-info, .home .big-post-highlight .post-card .small-up-3 > .post-image, .small-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .small-up-4 {
    flex-wrap: wrap;
  }
  .small-up-4 > .column, .home .big-post-highlight .post-card .small-up-4 > .post-info, .home .big-post-highlight .post-card .small-up-4 > .post-image, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .small-up-5 {
    flex-wrap: wrap;
  }
  .small-up-5 > .column, .home .big-post-highlight .post-card .small-up-5 > .post-info, .home .big-post-highlight .post-card .small-up-5 > .post-image, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .small-up-6 {
    flex-wrap: wrap;
  }
  .small-up-6 > .column, .home .big-post-highlight .post-card .small-up-6 > .post-info, .home .big-post-highlight .post-card .small-up-6 > .post-image, .small-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .small-up-7 {
    flex-wrap: wrap;
  }
  .small-up-7 > .column, .home .big-post-highlight .post-card .small-up-7 > .post-info, .home .big-post-highlight .post-card .small-up-7 > .post-image, .small-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .small-up-8 {
    flex-wrap: wrap;
  }
  .small-up-8 > .column, .home .big-post-highlight .post-card .small-up-8 > .post-info, .home .big-post-highlight .post-card .small-up-8 > .post-image, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .small-collapse > .column, .home .big-post-highlight .post-card .small-collapse > .post-info, .home .big-post-highlight .post-card .small-collapse > .post-image, .small-collapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .small-uncollapse > .column, .home .big-post-highlight .post-card .small-uncollapse > .post-info, .home .big-post-highlight .post-card .small-uncollapse > .post-image, .small-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.smaller-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.smaller-offset-0 {
  margin-left: 0%;
}

.smaller-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.smaller-offset-1 {
  margin-left: 8.3333333333%;
}

.smaller-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.smaller-offset-2 {
  margin-left: 16.6666666667%;
}

.smaller-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.smaller-offset-3 {
  margin-left: 25%;
}

.smaller-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.smaller-offset-4 {
  margin-left: 33.3333333333%;
}

.smaller-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.smaller-offset-5 {
  margin-left: 41.6666666667%;
}

.smaller-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.smaller-offset-6 {
  margin-left: 50%;
}

.smaller-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.smaller-offset-7 {
  margin-left: 58.3333333333%;
}

.smaller-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.smaller-offset-8 {
  margin-left: 66.6666666667%;
}

.smaller-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.smaller-offset-9 {
  margin-left: 75%;
}

.smaller-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.smaller-offset-10 {
  margin-left: 83.3333333333%;
}

.smaller-12, .colunistas-row .et_pb_column_1_4, .colunistas-row .et_pb_column_1_2, .posts-row .et_pb_column, .home .et_pb_column_3_4 .small-post-highlight .post-card, .small-post-highlight .post-card, .big-post-highlight .post-card, .home .big-post-highlight .post-card .post-info, .home .big-post-highlight .post-card .post-image, #respond, ol.commentlist, .et_pb_column_1_4 .small-post-highlight .post-card {
  flex: 0 0 100%;
  max-width: 100%;
}

.smaller-offset-11 {
  margin-left: 91.6666666667%;
}

.smaller-order-1 {
  order: 1;
}

.smaller-order-2 {
  order: 2;
}

.smaller-order-3 {
  order: 3;
}

.smaller-order-4 {
  order: 4;
}

.smaller-order-5 {
  order: 5;
}

.smaller-order-6 {
  order: 6;
}

.smaller-up-1 {
  flex-wrap: wrap;
}
.smaller-up-1 > .column, .home .big-post-highlight .post-card .smaller-up-1 > .post-info, .home .big-post-highlight .post-card .smaller-up-1 > .post-image, .smaller-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.smaller-up-2 {
  flex-wrap: wrap;
}
.smaller-up-2 > .column, .home .big-post-highlight .post-card .smaller-up-2 > .post-info, .home .big-post-highlight .post-card .smaller-up-2 > .post-image, .smaller-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.smaller-up-3 {
  flex-wrap: wrap;
}
.smaller-up-3 > .column, .home .big-post-highlight .post-card .smaller-up-3 > .post-info, .home .big-post-highlight .post-card .smaller-up-3 > .post-image, .smaller-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.smaller-up-4 {
  flex-wrap: wrap;
}
.smaller-up-4 > .column, .home .big-post-highlight .post-card .smaller-up-4 > .post-info, .home .big-post-highlight .post-card .smaller-up-4 > .post-image, .smaller-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.smaller-up-5 {
  flex-wrap: wrap;
}
.smaller-up-5 > .column, .home .big-post-highlight .post-card .smaller-up-5 > .post-info, .home .big-post-highlight .post-card .smaller-up-5 > .post-image, .smaller-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.smaller-up-6 {
  flex-wrap: wrap;
}
.smaller-up-6 > .column, .home .big-post-highlight .post-card .smaller-up-6 > .post-info, .home .big-post-highlight .post-card .smaller-up-6 > .post-image, .smaller-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.smaller-up-7 {
  flex-wrap: wrap;
}
.smaller-up-7 > .column, .home .big-post-highlight .post-card .smaller-up-7 > .post-info, .home .big-post-highlight .post-card .smaller-up-7 > .post-image, .smaller-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.smaller-up-8 {
  flex-wrap: wrap;
}
.smaller-up-8 > .column, .home .big-post-highlight .post-card .smaller-up-8 > .post-info, .home .big-post-highlight .post-card .smaller-up-8 > .post-image, .smaller-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.smaller-expand {
  flex: 1 1 0px;
}

.smaller-flex-dir-row {
  flex-direction: row;
}

.smaller-flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.smaller-flex-dir-column {
  flex-direction: column;
}

.smaller-flex-dir-column-reverse {
  flex-direction: column-reverse;
}

.smaller-flex-child-auto {
  flex: 1 1 auto;
}

.smaller-flex-child-grow {
  flex: 1 0 auto;
}

.smaller-flex-child-shrink {
  flex: 0 1 auto;
}

.row.smaller-unstack > .column, .home .big-post-highlight .post-card .row.smaller-unstack > .post-info, .home .big-post-highlight .post-card .row.smaller-unstack > .post-image, .home .big-post-highlight .post-card a.smaller-unstack > .column, .home .big-post-highlight .post-card a.smaller-unstack > .post-info, .home .big-post-highlight .post-card a.smaller-unstack > .post-image, .row.smaller-unstack > .columns, .home .big-post-highlight .post-card a.smaller-unstack > .columns {
  flex: 0 0 100%;
  flex: 1 1 0px;
}

.smaller-collapse > .column, .home .big-post-highlight .post-card .smaller-collapse > .post-info, .home .big-post-highlight .post-card .smaller-collapse > .post-image, .smaller-collapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.smaller-uncollapse > .column, .home .big-post-highlight .post-card .smaller-uncollapse > .post-info, .home .big-post-highlight .post-card .smaller-uncollapse > .post-image, .smaller-uncollapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6, .colunistas-row .et_pb_column_1_4, .small-post-highlight .post-card, #respond, ol.commentlist {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12, .et_pb_column_1_4 .small-post-highlight .post-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .home .big-post-highlight .post-card .medium-up-1 > .post-info, .home .big-post-highlight .post-card .medium-up-1 > .post-image, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .home .big-post-highlight .post-card .medium-up-2 > .post-info, .home .big-post-highlight .post-card .medium-up-2 > .post-image, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .home .big-post-highlight .post-card .medium-up-3 > .post-info, .home .big-post-highlight .post-card .medium-up-3 > .post-image, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .home .big-post-highlight .post-card .medium-up-4 > .post-info, .home .big-post-highlight .post-card .medium-up-4 > .post-image, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .home .big-post-highlight .post-card .medium-up-5 > .post-info, .home .big-post-highlight .post-card .medium-up-5 > .post-image, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .home .big-post-highlight .post-card .medium-up-6 > .post-info, .home .big-post-highlight .post-card .medium-up-6 > .post-image, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .home .big-post-highlight .post-card .medium-up-7 > .post-info, .home .big-post-highlight .post-card .medium-up-7 > .post-image, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .home .big-post-highlight .post-card .medium-up-8 > .post-info, .home .big-post-highlight .post-card .medium-up-8 > .post-image, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 40em) {
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.medium-unstack > .column, .home .big-post-highlight .post-card .row.medium-unstack > .post-info, .home .big-post-highlight .post-card .row.medium-unstack > .post-image, .home .big-post-highlight .post-card a.medium-unstack > .column, .home .big-post-highlight .post-card a.medium-unstack > .post-info, .home .big-post-highlight .post-card a.medium-unstack > .post-image, .row.medium-unstack > .columns, .home .big-post-highlight .post-card a.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .home .big-post-highlight .post-card .row.medium-unstack > .post-info, .home .big-post-highlight .post-card .row.medium-unstack > .post-image, .home .big-post-highlight .post-card a.medium-unstack > .column, .home .big-post-highlight .post-card a.medium-unstack > .post-info, .home .big-post-highlight .post-card a.medium-unstack > .post-image, .row.medium-unstack > .columns, .home .big-post-highlight .post-card a.medium-unstack > .columns {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .home .big-post-highlight .post-card .medium-collapse > .post-info, .home .big-post-highlight .post-card .medium-collapse > .post-image, .medium-collapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .medium-uncollapse > .column, .home .big-post-highlight .post-card .medium-uncollapse > .post-info, .home .big-post-highlight .post-card .medium-uncollapse > .post-image, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3, .archive .sidebar.hide + .column .post-card, .archive .sidebar.hide + .columns .post-card, .archive .home .big-post-highlight .post-card .sidebar.hide + .post-image .post-card, .home .big-post-highlight .post-card .archive .sidebar.hide + .post-image .post-card, .archive .home .big-post-highlight .post-card .sidebar.hide + .post-info .post-card, .home .big-post-highlight .post-card .archive .sidebar.hide + .post-info .post-card, .colunistas-row .et_pb_column_1_4, .home .big-post-highlight .post-card .post-info, #respond {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4, .home .et_pb_column_3_4 .small-post-highlight .post-card, .small-post-highlight .post-card {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6, .colunistas-row .et_pb_column_1_2, .posts-row .et_pb_column {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8, .home .big-post-highlight .post-card .post-image {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9, ol.commentlist {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12, .et_pb_column_1_4 .small-post-highlight .post-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .home .big-post-highlight .post-card .large-up-1 > .post-info, .home .big-post-highlight .post-card .large-up-1 > .post-image, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .home .big-post-highlight .post-card .large-up-2 > .post-info, .home .big-post-highlight .post-card .large-up-2 > .post-image, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .home .big-post-highlight .post-card .large-up-3 > .post-info, .home .big-post-highlight .post-card .large-up-3 > .post-image, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .home .big-post-highlight .post-card .large-up-4 > .post-info, .home .big-post-highlight .post-card .large-up-4 > .post-image, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .home .big-post-highlight .post-card .large-up-5 > .post-info, .home .big-post-highlight .post-card .large-up-5 > .post-image, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .home .big-post-highlight .post-card .large-up-6 > .post-info, .home .big-post-highlight .post-card .large-up-6 > .post-image, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .home .big-post-highlight .post-card .large-up-7 > .post-info, .home .big-post-highlight .post-card .large-up-7 > .post-image, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .home .big-post-highlight .post-card .large-up-8 > .post-info, .home .big-post-highlight .post-card .large-up-8 > .post-image, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.large-unstack > .column, .home .big-post-highlight .post-card .row.large-unstack > .post-info, .home .big-post-highlight .post-card .row.large-unstack > .post-image, .home .big-post-highlight .post-card a.large-unstack > .column, .home .big-post-highlight .post-card a.large-unstack > .post-info, .home .big-post-highlight .post-card a.large-unstack > .post-image, .row.large-unstack > .columns, .home .big-post-highlight .post-card a.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .home .big-post-highlight .post-card .row.large-unstack > .post-info, .home .big-post-highlight .post-card .row.large-unstack > .post-image, .home .big-post-highlight .post-card a.large-unstack > .column, .home .big-post-highlight .post-card a.large-unstack > .post-info, .home .big-post-highlight .post-card a.large-unstack > .post-image, .row.large-unstack > .columns, .home .big-post-highlight .post-card a.large-unstack > .columns {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .home .big-post-highlight .post-card .large-collapse > .post-info, .home .big-post-highlight .post-card .large-collapse > .post-image, .large-collapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .large-uncollapse > .column, .home .big-post-highlight .post-card .large-uncollapse > .post-info, .home .big-post-highlight .post-card .large-uncollapse > .post-image, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 68.75em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xlarge-offset-0 {
    margin-left: 0%;
  }
  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-offset-3 {
    margin-left: 25%;
  }
  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xlarge-offset-6 {
    margin-left: 50%;
  }
  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xlarge-offset-9 {
    margin-left: 75%;
  }
  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xlarge-order-1 {
    order: 1;
  }
  .xlarge-order-2 {
    order: 2;
  }
  .xlarge-order-3 {
    order: 3;
  }
  .xlarge-order-4 {
    order: 4;
  }
  .xlarge-order-5 {
    order: 5;
  }
  .xlarge-order-6 {
    order: 6;
  }
  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .home .big-post-highlight .post-card .xlarge-up-1 > .post-info, .home .big-post-highlight .post-card .xlarge-up-1 > .post-image, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .home .big-post-highlight .post-card .xlarge-up-2 > .post-info, .home .big-post-highlight .post-card .xlarge-up-2 > .post-image, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .home .big-post-highlight .post-card .xlarge-up-3 > .post-info, .home .big-post-highlight .post-card .xlarge-up-3 > .post-image, .xlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .home .big-post-highlight .post-card .xlarge-up-4 > .post-info, .home .big-post-highlight .post-card .xlarge-up-4 > .post-image, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .home .big-post-highlight .post-card .xlarge-up-5 > .post-info, .home .big-post-highlight .post-card .xlarge-up-5 > .post-image, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .home .big-post-highlight .post-card .xlarge-up-6 > .post-info, .home .big-post-highlight .post-card .xlarge-up-6 > .post-image, .xlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .home .big-post-highlight .post-card .xlarge-up-7 > .post-info, .home .big-post-highlight .post-card .xlarge-up-7 > .post-image, .xlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .home .big-post-highlight .post-card .xlarge-up-8 > .post-info, .home .big-post-highlight .post-card .xlarge-up-8 > .post-image, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 68.75em) and (min-width: 68.75em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
@media screen and (min-width: 68.75em) {
  .xlarge-flex-dir-row {
    flex-direction: row;
  }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xlarge-flex-dir-column {
    flex-direction: column;
  }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xlarge-flex-child-auto {
    flex: 1 1 auto;
  }
  .xlarge-flex-child-grow {
    flex: 1 0 auto;
  }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.xlarge-unstack > .column, .home .big-post-highlight .post-card .row.xlarge-unstack > .post-info, .home .big-post-highlight .post-card .row.xlarge-unstack > .post-image, .home .big-post-highlight .post-card a.xlarge-unstack > .column, .home .big-post-highlight .post-card a.xlarge-unstack > .post-info, .home .big-post-highlight .post-card a.xlarge-unstack > .post-image, .row.xlarge-unstack > .columns, .home .big-post-highlight .post-card a.xlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 68.75em) {
  .row.xlarge-unstack > .column, .home .big-post-highlight .post-card .row.xlarge-unstack > .post-info, .home .big-post-highlight .post-card .row.xlarge-unstack > .post-image, .home .big-post-highlight .post-card a.xlarge-unstack > .column, .home .big-post-highlight .post-card a.xlarge-unstack > .post-info, .home .big-post-highlight .post-card a.xlarge-unstack > .post-image, .row.xlarge-unstack > .columns, .home .big-post-highlight .post-card a.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xlarge-collapse > .column, .home .big-post-highlight .post-card .xlarge-collapse > .post-info, .home .big-post-highlight .post-card .xlarge-collapse > .post-image, .xlarge-collapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .xlarge-uncollapse > .column, .home .big-post-highlight .post-card .xlarge-uncollapse > .post-info, .home .big-post-highlight .post-card .xlarge-uncollapse > .post-image, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (min-width: 75em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .xxlarge-offset-0 {
    margin-left: 0%;
  }
  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }
  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-offset-3 {
    margin-left: 25%;
  }
  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }
  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .xxlarge-offset-6 {
    margin-left: 50%;
  }
  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }
  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .xxlarge-offset-9 {
    margin-left: 75%;
  }
  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }
  .xxlarge-order-1 {
    order: 1;
  }
  .xxlarge-order-2 {
    order: 2;
  }
  .xxlarge-order-3 {
    order: 3;
  }
  .xxlarge-order-4 {
    order: 4;
  }
  .xxlarge-order-5 {
    order: 5;
  }
  .xxlarge-order-6 {
    order: 6;
  }
  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .home .big-post-highlight .post-card .xxlarge-up-1 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-1 > .post-image, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .home .big-post-highlight .post-card .xxlarge-up-2 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-2 > .post-image, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .home .big-post-highlight .post-card .xxlarge-up-3 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-3 > .post-image, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .home .big-post-highlight .post-card .xxlarge-up-4 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-4 > .post-image, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .home .big-post-highlight .post-card .xxlarge-up-5 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-5 > .post-image, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .home .big-post-highlight .post-card .xxlarge-up-6 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-6 > .post-image, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .home .big-post-highlight .post-card .xxlarge-up-7 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-7 > .post-image, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .home .big-post-highlight .post-card .xxlarge-up-8 > .post-info, .home .big-post-highlight .post-card .xxlarge-up-8 > .post-image, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 75em) and (min-width: 75em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
@media screen and (min-width: 75em) {
  .xxlarge-flex-dir-row {
    flex-direction: row;
  }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .xxlarge-flex-dir-column {
    flex-direction: column;
  }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto;
  }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto;
  }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto;
  }
}
.row.xxlarge-unstack > .column, .home .big-post-highlight .post-card .row.xxlarge-unstack > .post-info, .home .big-post-highlight .post-card .row.xxlarge-unstack > .post-image, .home .big-post-highlight .post-card a.xxlarge-unstack > .column, .home .big-post-highlight .post-card a.xxlarge-unstack > .post-info, .home .big-post-highlight .post-card a.xxlarge-unstack > .post-image, .row.xxlarge-unstack > .columns, .home .big-post-highlight .post-card a.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 75em) {
  .row.xxlarge-unstack > .column, .home .big-post-highlight .post-card .row.xxlarge-unstack > .post-info, .home .big-post-highlight .post-card .row.xxlarge-unstack > .post-image, .home .big-post-highlight .post-card a.xxlarge-unstack > .column, .home .big-post-highlight .post-card a.xxlarge-unstack > .post-info, .home .big-post-highlight .post-card a.xxlarge-unstack > .post-image, .row.xxlarge-unstack > .columns, .home .big-post-highlight .post-card a.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
  .xxlarge-collapse > .column, .home .big-post-highlight .post-card .xxlarge-collapse > .post-info, .home .big-post-highlight .post-card .xxlarge-collapse > .post-image, .xxlarge-collapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .xxlarge-uncollapse > .column, .home .big-post-highlight .post-card .xxlarge-uncollapse > .post-info, .home .big-post-highlight .post-card .xxlarge-uncollapse > .post-image, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

@media print, screen and (min-width: 30em) {
  .column-block {
    margin-bottom: 1.25rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

@font-face {
  font-family: "Lucida Sans" !important;
  src: url("/wp-content/themes/teoriaedebate/resources/scss/base/lucida_sans_regular-webfont.woff2") format("woff2"), url("/wp-content/themes/teoriaedebate/resources/scss/base/lucida_sans_regular-webfont.woff") format("woff") !important;
  font-weight: normal !important;
  font-style: normal !important;
}
body.et-pb-theme-teoria #app * {
  font-family: "Lucida Sans", sans-serif !important;
}

body.et-pb-theme-teoria #app .category,
body.et-pb-theme-teoria #app .post-category {
  font-size: 12px;
  text-transform: uppercase;
}

body.et-pb-theme-teoria #app .category,
body.et-pb-theme-teoria #app .post-category {
  font-size: 12px;
  text-transform: uppercase;
}

.post-card .post-category {
  margin: 10px 0 2px;
}

.post-card .post-title {
  border-bottom: 1px solid transparent;
  display: inline;
  font-size: 24px !important;
  font-weight: 300;
  line-height: 1.2;
  transition: border 0.3s ease;
}

body.single-colunas .et_pb_column.et_pb_column_1_4 .post-title {
  font-weight: 300 !important;
  font-size: 28px !important;
}

body.single-colunas .et_pb_column.et_pb_column_1_4 .post-card,
.post-card {
  margin-bottom: 15px;
}

.highlight-title {
  font-weight: 500 !important;
  text-transform: lowercase !important;
  line-height: 1 !important;
  margin-bottom: 15px !important;
}

.no-p {
  padding: 0 !important;
}

.editoria-economia .post-category, .editoria-economia .category {
  color: #81a9a8 !important;
}

.menu .menu-item-economia:hover a, .menu .menu-item-economia.current-menu-item a {
  background-color: #81a9a8 !important;
}

.editoria-cultura .post-category, .editoria-cultura .category {
  color: #ef902d !important;
}

.menu .menu-item-cultura:hover a, .menu .menu-item-cultura.current-menu-item a {
  background-color: #ef902d !important;
}

.editoria-internacional .post-category, .editoria-internacional .category {
  color: #173426 !important;
}

.menu .menu-item-internacional:hover a, .menu .menu-item-internacional.current-menu-item a {
  background-color: #173426 !important;
}

.editoria-mundo-do-trabalho .post-category, .editoria-mundo-do-trabalho .category {
  color: #4d8376 !important;
}

.menu .menu-item-mundo-do-trabalho:hover a, .menu .menu-item-mundo-do-trabalho.current-menu-item a {
  background-color: #4d8376 !important;
}

.editoria-nacional .post-category, .editoria-nacional .category {
  color: #777b2d !important;
}

.menu .menu-item-nacional:hover a, .menu .menu-item-nacional.current-menu-item a {
  background-color: #777b2d !important;
}

.editoria-politica .post-category, .editoria-politica .category {
  color: #484a1c !important;
}

.menu .menu-item-politica:hover a, .menu .menu-item-politica.current-menu-item a {
  background-color: #484a1c !important;
}

.editoria-sociedade .post-category, .editoria-sociedade .category {
  color: #214433 !important;
}

.menu .menu-item-sociedade:hover a, .menu .menu-item-sociedade.current-menu-item a {
  background-color: #214433 !important;
}

[style='background-image: url("undefined")!important;'] {
  display: none !important;
}

.content-wrapper {
  background: linear-gradient(to bottom, #DCDCD2 0, #DCDCD2 100px, #FFFFFF 900px) !important;
}

.fa {
  font-family: FontAwesome !important;
}

.ted--pre-footer .edicao .logo-ted span {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

body:not(.home) a.btn-small.load-more {
  margin-bottom: 20px;
}

body.tax-tipodemultimdia .sidebar ul li a {
  text-transform: inherit !important;
}

.multimidia-template-default .flickity-viewport {
  height: 600px;
}

.multimidia-template-default .multimidia-item {
  height: 90%;
}

.multimidia-template-default .multimidia-item img {
  height: 90%;
}

.multimidia-template-default .flickity-slider {
  left: 150px !important;
}

.multimidia-template-default .flickity-page-dots {
  padding-bottom: 30px;
}

.no-p {
  padding: 0px !important;
}

.sidebar ul {
  margin: 0 !important;
  padding: 10px !important;
  list-style: none !important;
}
.sidebar ul li:before {
  content: "" !important;
  border-color: transparent #948f8f !important;
  border-style: solid !important;
  border-width: 0.35em 0 0.35em 0.45em !important;
  display: block !important;
  height: 0 !important;
  width: 0 !important;
  left: -1em !important;
  top: 24px !important;
  position: relative !important;
}
.sidebar ul li a {
  padding: 10px !important;
  font-size: 21px !important;
  text-transform: lowercase !important;
}
.sidebar ul li a:hover, .sidebar ul li a:active, .sidebar ul li a:focus {
  color: #ba2131 !important;
}
.sidebar ul li:hover, .sidebar ul li:active, .sidebar ul li:focus {
  color: #ba2131 !important;
}
.sidebar ul li:hover:before, .sidebar ul li:active:before, .sidebar ul li:focus:before {
  border-color: transparent #ba2131 !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

h3#comments {
  margin-top: 50px !important;
  width: 100% !important;
}
@media print, screen and (max-width: 63.99875em) {
  h3#comments {
    margin: 30px 20px 0 20px !important;
  }
}

ol.commentlist {
  list-style: none !important;
  margin: 20px 0 !important;
  text-indent: 0 !important;
  padding: 0 !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li {
    margin: 0 20px !important;
  }
}
@media print, screen and (min-width: 64em) {
  ol.commentlist li {
    margin: 0 30px 0 0 !important;
  }
}
ol.commentlist li.bypostauthor > .comment-body div.vcard cite.fn {
  color: #903743 !important;
}
ol.commentlist li.comment {
  border-top: 1px solid #BFC0B9 !important;
  padding: 30px 0px 0px 30px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment {
    padding: 15px 0px 0px 15px !important;
  }
}
ol.commentlist li.comment div.vcard cite.fn {
  display: inline-block !important;
  font-style: normal !important;
  font-size: 18px !important;
  font-weight: bold !important;
}
ol.commentlist li.comment div.vcard cite.fn:before {
  display: none !important;
}
ol.commentlist li.comment div.vcard img.avatar {
  border: 1px solid #ccc !important;
  float: left !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  margin: -1px 15px 0 0 !important;
}
ol.commentlist li.comment div.vcard span.says {
  font-size: 12px !important;
  display: inline-block !important;
}
ol.commentlist li.comment div.comment-meta a {
  font-size: 12px !important;
  color: #BFC0B9 !important;
}
ol.commentlist li.comment p {
  font-size: 14px !important;
  margin: 15px 0 !important;
  padding-right: 30px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment p {
    padding-right: 15px !important;
  }
}
ol.commentlist li.comment ul {
  list-style: square !important;
  margin: 0 0 1em 2em !important;
}
ol.commentlist li.comment div.reply {
  padding-bottom: 30px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment div.reply {
    padding-bottom: 15px !important;
  }
}
ol.commentlist li.comment div.reply a {
  font-size: 13px !important;
  font-weight: bold !important;
}
ol.commentlist li.comment ul.children {
  list-style: none !important;
  margin: 0 !important;
  text-indent: 0 !important;
}
ol.commentlist li.comment ul.children li.depth-2 {
  margin: 0 0 30px 0px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment ul.children li.depth-2 {
    margin: 0 0px 15px 0 !important;
  }
}
ol.commentlist li.comment ul.children li.depth-3 {
  margin: 0 0 30px 0px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment ul.children li.depth-3 {
    margin: 0 0px 15px 0 !important;
  }
}
ol.commentlist li.comment ul.children li.depth-4 {
  margin: 0 0 30px 0px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment ul.children li.depth-4 {
    margin: 0 0px 15px 0 !important;
  }
}
ol.commentlist li.comment ul.children li.depth-5 {
  margin: 0 !important;
  padding-bottom: 30px !important;
}
@media print, screen and (max-width: 63.99875em) {
  ol.commentlist li.comment ul.children li.depth-5 {
    padding-bottom: 15px !important;
  }
}
ol.commentlist li.comment ul.children li.depth-5 p {
  margin-bottom: 0 !important;
}
ol.commentlist li.parent {
  border-left: 1px dotted rgba(17, 17, 17, 0.1) !important;
  transition: all 200ms ease-in-out !important;
}
ol.commentlist li.parent:hover {
  border-left: 1px dotted rgba(17, 17, 17, 0.4) !important;
}
#respond {
  margin: 20px 0 50px 0;
}
@media print, screen and (min-width: 64em) {
  #respond {
    margin-top: 10px !important;
  }
}
@media print, screen and (max-width: 63.99875em) {
  #respond h3, #respond form {
    padding: 0 15px !important;
  }
}
#respond .submit {
  border-radius: 5px !important;
  color: white !important;
  background: #d41f29 !important;
  border: 0 !important;
  font-family: "Roboto Condensed", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  cursor: pointer !important;
  padding: 6px 10px 5px 10px !important;
  text-transform: uppercase !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
#respond .submit:hover {
  background: #b01a22 !important;
}

body:not(.single-colunas) .highlight-title {
  color: #903743 !important;
  font-weight: 300 !important;
}

body.single-colunas .highlight-title {
  color: #948f8f !important;
}

.home .colunistas-highlight .item + .item {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item {
  width: 100% !important;
  border-top: none !important;
}
.et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .column, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .home .big-post-highlight .post-card .post-info, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .post-info, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .home .big-post-highlight .post-card .post-image, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .post-image, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item .column, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item .post-info, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item .post-image, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item .column, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item .post-info, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item .post-image, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .columns, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item .columns, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item .columns {
  width: 100% !important;
}
.et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row a, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item a, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item a {
  display: flex !important;
  justify-content: center !important;
}
.et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .name, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item .name, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item .name, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .item.row .excerpt, .et_pb_column.et_pb_column_1_4 .colunistas-highlight .home .big-post-highlight .post-card a.item .excerpt, .home .big-post-highlight .post-card .et_pb_column.et_pb_column_1_4 .colunistas-highlight a.item .excerpt {
  width: 100% !important;
}

.et_pb_column_1_2 .colunistas-highlight .item {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}

.colunistas-highlight .item {
  flex: 0 0 50% !important;
  max-width: 50% !important;
  width: 50% !important;
  float: left !important;
  margin-bottom: 20px !important;
}
.colunistas-highlight .item + .item {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.estantes-section {
  background-color: #f3eeee !important;
  background: linear-gradient(to right, #FFFFFF 0%, #DCDCD2 50%, #FFFFFF 100%) !important;
  margin-bottom: 30px !important;
}

.estante-highlight:before {
  display: none !important;
}

.estante-highlight .img {
  float: left !important;
}

.home {
  overflow-x: hidden !important;
}
.home .big-post-highlight {
  margin: 0 0 60px 0 !important;
  position: relative !important;
}
@media (max-width: 1210px) {
  .home .big-post-highlight .flickity-prev-next-button {
    margin-top: 0 !important;
  }
}
.home .big-post-highlight:after {
  content: "" !important;
  border-bottom: 2px solid #a7a8a0 !important;
  display: block !important;
  position: absolute !important;
  height: 530px !important;
  left: 0 !important;
  width: 100% !important;
  pointer-events: none !important;
  z-index: 0 !important;
  bottom: 0 !important;
}
@media print, screen and (min-width: 64em) {
  .home .big-post-highlight:after {
    width: calc(100% + (100vw - 100%) / 2) !important;
    bottom: -30px !important;
    background-image: url(/wp-content/themes/perseuabramo/assets/img/grafismo-slider.png) !important;
    background-repeat: no-repeat !important;
    background-position: bottom -1px right 0 !important;
  }
}
@media (max-width: 1250px) {
  .home .big-post-highlight:after {
    background-size: 10%;
  }
}
.home .big-post-highlight .post-card {
  padding: 0 !important;
  position: relative;
}
.home .big-post-highlight .post-card * {
  z-index: 1 !important;
  position: relative !important;
}
@media print, screen and (min-width: 64em) {
  .home .big-post-highlight .post-card .post-info {
    max-width: none !important;
    flex-grow: 1 !important;
  }
}
.home .big-post-highlight .post-card .post-category {
  margin: 0 0 30px !important;
}
.home .big-post-highlight .post-card .post-title {
  color: #214433 !important;
  font-size: 30px !important;
  font-family: "Lucida Sans", sans-serif !important !important;
  color: #214433 !important;
  border-bottom: 1px solid transparent !important;
  transition: all 0.3s ease !important;
}
.home .big-post-highlight .post-card .post-title:focus, .home .big-post-highlight .post-card .post-title:active {
  color: #214433 !important;
  border-bottom: 1px solid #214433 !important;
}
@media screen and (min-width: 68.75em) {
  .home .big-post-highlight .post-card .post-title:hover, .home .big-post-highlight .post-card .post-title:focus, .home .big-post-highlight .post-card .post-title:active {
    color: #214433 !important;
    border-bottom: 1px solid #214433 !important;
  }
}
.home .big-post-highlight .post-card .post-description {
  color: #403f3c !important;
  font-size: 18px !important;
}
.home .big-post-highlight .post-card .post-authors {
  color: #403f3c !important;
  font-size: 14px !important;
  margin-top: 30px !important;
}
.home .small-post-highlight {
  border-bottom: 2px solid lightgray !important;
  margin-bottom: 50px !important;
}

.post-content p > a {
  text-decoration: underline;
  color: #BA2131;
}
.post-content .post-share {
  border-top: 0 !important;
  margin: 0 !important;
  padding-top: 0 !important;
  margin-top: 10px !important;
}
.post-content .post-share .social-links a {
  color: #948f8f !important;
  font-size: 1.2rem !important;
  transition: color 0.3s ease !important;
}
.post-content .post-share .social-links a:before {
  color: #948f8f !important;
}
.post-content .post-share .social-links a:active, .post-content .post-share .social-links a:focus {
  color: #403f3c !important;
}
.post-content .post-share .social-links a:active:before, .post-content .post-share .social-links a:focus:before {
  color: #403f3c !important;
}
.post-content .post-share .social-links a span {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.big-post-highlight .post-card {
  margin-bottom: 20px !important;
}
.big-post-highlight .post-card .post-image {
  height: 40vmax !important;
}
@media print, screen and (min-width: 64em) {
  .big-post-highlight .post-card .post-image {
    height: 530px !important;
  }
}
.big-post-highlight .post-card .post-title {
  font-size: 24px !important;
}

.small-post-highlight .post-card {
  margin-bottom: 20px !important;
}
@media print, screen and (min-width: 64em) {
  .small-post-highlight .post-card {
    height: 330px !important;
  }
}
.small-post-highlight .post-card .post-image {
  height: 20vmax !important;
}
@media print, screen and (min-width: 64em) {
  .small-post-highlight .post-card .post-image {
    height: 160px !important;
  }
}

.post-small-list ul li {
  border-left: none !important;
}
.post-small-list .number {
  font-size: 40px !important;
  padding-left: 4px !important;
}
.post-small-list .category, .post-small-list .title {
  margin: 0 0 5px !important;
}
.post-small-list + .post-small-list {
  margin-top: 30px !important;
}

.post-card .post-image-credit {
  color: #948f8f !important;
  font-size: 12px !important;
  margin: 4px 0 0 !important;
}

.highlight-emdebate .header, .highlight-teoriaedebate .header {
  background: #FFFFFF !important;
}
.highlight-emdebate:after, .highlight-teoriaedebate:after {
  background: #FFFFFF !important;
}

.opinioes.item p.title {
  color: #c80c2f !important;
  font-size: 24px !important;
}

.opinions.post-card p {
  display: none !important;
}
.opinions.post-card .title {
  color: #403F3C !important;
  font-size: 21px !important;
}

.opinions-text .title {
  font-size: 24px !important;
  color: #903743 !important;
  font-weight: bold !important;
}
.opinions-text .title + p {
  display: none !important;
}
.opinions-text .post-authors:not(.chamada) + p {
  display: none !important;
}

.opinions .title, .opinions .chamada, .opinions .post-authors {
  cursor: pointer !important;
}
.opinions.opinions-text {
  display: none;
}
.opinions:not(.opinions-text) .text {
  max-height: 0px !important;
  overflow: hidden !important;
  transition: all 0.6s ease-in-out !important;
}
.opinions:not(.opinions-text) .text.active {
  max-height: 4000px !important;
}

.opinioes h3 {
  color: black !important;
  margin-bottom: 15px !important;
}
.opinioes .text, .opinioes .title {
  margin-bottom: 5px !important;
}
.opinioes .text {
  color: #505050 !important;
}
.opinioes .author {
  font-size: 14px !important;
}

.highlight-emdebate .header, .highlight-teoriaedebate .header {
  background: #FFFFFF !important;
}
.highlight-emdebate:after, .highlight-teoriaedebate:after {
  background: #FFFFFF !important;
}

.opinioes.item p.title {
  color: #c80c2f !important;
  font-size: 24px !important;
}

.main-header {
  background: #a7a8a0 !important;
  background: linear-gradient(to bottom, #FFFFFF 0px, #DCDCD2 70%) !important;
  margin-bottom: 0 !important;
}
@media print, screen and (max-width: 63.99875em) {
  .main-header {
    padding-bottom: 30px !important;
  }
}
.main-header:after {
  content: "" !important;
  background: url(/wp-content/themes/perseuabramo/assets/img/FPA_grafismo_topo.png) !important;
  width: 250px !important;
  height: 107px !important;
  position: absolute !important;
  background-repeat: no-repeat !important;
  background-size: 129px !important;
  left: -1px !important;
  top: -15px !important;
}
@media print, screen and (max-width: 63.99875em) {
  .main-header:after {
    display: none !important;
  }
}
.main-header .logo-link {
  display: block !important;
  margin-bottom: -5px !important;
}
.main-header .logo-fpa {
  background-image: url(/wp-content/themes/teoriaedebate/assets/img/logo-td.png) !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 275px !important;
  margin-bottom: 0 !important;
  margin-left: 70px !important;
}
@media print, screen and (max-width: 39.99875em) {
  .main-header .logo-fpa {
    height: 35px !important;
    background-size: auto 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .main-header .logo-fpa {
    margin-left: 0 !important;
  }
}
.main-header .edicao {
  color: #403f3c !important;
  font-size: 13px !important;
}
@media print, screen and (max-width: 39.99875em) {
  .main-header .edicao {
    margin-top: 10px !important;
  }
}
.main-header .edicao a {
  color: #a7a8a0 !important;
  border-bottom: 1px solid transparent !important;
  transition: all 0.3s ease !important;
  color: #a7a8a0 !important;
  border-bottom: 1px solid transparent !important;
  transition: all 0.3s ease !important;
}
.main-header .edicao a:focus, .main-header .edicao a:active {
  color: #a7a8a0 !important;
  border-bottom: 1px solid #a7a8a0 !important;
}
@media screen and (min-width: 68.75em) {
  .main-header .edicao a:hover, .main-header .edicao a:focus, .main-header .edicao a:active {
    color: #a7a8a0 !important;
    border-bottom: 1px solid #a7a8a0 !important;
  }
}
.main-header .edicao p {
  margin-bottom: 0 !important;
}
.main-header .edicao p + p {
  margin-top: 5px !important;
}

.menu-editorias-container {
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
}
@media print, screen and (min-width: 64em) {
  .menu-editorias-container {
    background-color: transparent !important;
  }
}
.menu-editorias-container .search-input {
  background-color: transparent !important;
}

.menu-editorias .menu-item a {
  font-weight: 300 !important;
}

@media print, screen and (max-width: 39.99875em) {
  .header-logo .social-links {
    margin: 0 15px !important;
  }
}

.search-container {
  right: 0 !important;
}
@media print, screen and (min-width: 64em) {
  .search-container {
    bottom: 0 !important;
  }
  .search-container .search-input {
    bottom: 9px !important;
  }
  .search-container label[for=search-input-check] {
    bottom: 7px !important;
  }
}

.footer-logos {
  margin-left: -100px;
  margin-top: 10px;
}

.logo-instituto-lula {
  display: none;
}

.ted--pre-footer {
  position: relative !important;
  padding-top: 15px !important;
  padding-bottom: 50px !important;
  margin-bottom: -50px !important;
  overflow: hidden !important;
  background: linear-gradient(to bottom, #EDEDE8 0px, #FEFEFE 80px, #DADAD0 430px) !important;
}
.ted--pre-footer * {
  z-index: 9 !important;
}
.ted--pre-footer:after {
  content: "" !important;
  background-image: url(/wp-content/themes/teoriaedebate/assets/img/pre-footer__detail.png) !important;
  background-repeat: no-repeat !important;
  background-size: auto 100% !important;
  background-position: bottom center !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  width: 756px !important;
  height: calc(100% - 150px) !important;
  max-height: 445px !important;
  z-index: 0 !important;
  display: none !important;
}
@media print, screen and (min-width: 64em) {
  .ted--pre-footer:after {
    display: block !important;
  }
}
.ted--pre-footer .editorias--wrap {
  display: block !important;
  position: relative !important;
}
.ted--pre-footer .menu-editorias-container {
  border: 0 !important;
}
.ted--pre-footer .pre-footer-menu--wrap {
  margin-top: 15px !important;
  padding-top: 30px !important;
  border-top: 1px solid #DCDCDA !important;
}
.ted--pre-footer .edicao {
  width: 100% !important;
  margin-bottom: 20px !important;
  text-align: center !important;
}
@media print, screen and (min-width: 30em) {
  .ted--pre-footer .edicao {
    text-align: left !important;
  }
}
@media screen and (min-width: 75em) {
  .ted--pre-footer .edicao {
    text-align: right !important;
    margin-bottom: 0 !important;
  }
}
.ted--pre-footer .edicao .logo-ted {
  background-image: url(/wp-content/themes/teoriaedebate/assets/img/logo-td.png) !important;
  background-repeat: no-repeat !important;
  height: 45px !important;
  width: 275px !important;
  margin: 0 auto !important;
  margin-bottom: 20px !important;
}
@media print, screen and (min-width: 30em) {
  .ted--pre-footer .edicao .logo-ted {
    margin: 0 0 20px 0 !important;
  }
}
.ted--pre-footer .edicao .logo-ted span {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}
.ted--pre-footer .edicao p {
  font-size: 14px !important;
  margin: 0 !important;
  color: #565552 !important;
}
.ted--pre-footer .edicao p a {
  color: #BFC0B9 !important;
}
.ted--pre-footer #pre-footer-menu-ted, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  margin-left: 0 !important;
}
.ted--pre-footer #pre-footer-menu-ted > li.page_item, .ted--pre-footer #pre-footer-menu-ted > li.menu-item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.page_item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.menu-item {
  margin-bottom: 5px !important;
  list-style-type: none !important;
  flex: 0 0 100% !important;
  text-align: center !important;
}
@media print, screen and (min-width: 30em) {
  .ted--pre-footer #pre-footer-menu-ted > li.page_item, .ted--pre-footer #pre-footer-menu-ted > li.menu-item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.page_item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.menu-item {
    flex: 0 0 50% !important;
    text-align: left !important;
  }
}
@media print, screen and (min-width: 40em) {
  .ted--pre-footer #pre-footer-menu-ted > li.page_item, .ted--pre-footer #pre-footer-menu-ted > li.menu-item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.page_item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.menu-item {
    flex: 0 0 33.3% !important;
  }
}
.ted--pre-footer #pre-footer-menu-ted > li.page_item > a, .ted--pre-footer #pre-footer-menu-ted > li.menu-item > a, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.page_item > a, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) > li.menu-item > a {
  text-transform: uppercase !important;
  font-weight: 300 !important;
  font-size: 13px !important;
  color: #BB1B2E !important;
}
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu, .ted--pre-footer #pre-footer-menu-ted ul.children, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children {
  margin: 10px 0 0 0 !important;
  padding: 0 !important;
}
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu li.page_item,
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu li.menu-item, .ted--pre-footer #pre-footer-menu-ted ul.children li.page_item,
.ted--pre-footer #pre-footer-menu-ted ul.children li.menu-item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu li.page_item,
.ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu li.menu-item, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children li.page_item,
.ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children li.menu-item {
  list-style-type: none !important;
  padding-right: 20px !important;
}
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu li.page_item a,
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu li.menu-item a, .ted--pre-footer #pre-footer-menu-ted ul.children li.page_item a,
.ted--pre-footer #pre-footer-menu-ted ul.children li.menu-item a, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu li.page_item a,
.ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu li.menu-item a, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children li.page_item a,
.ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children li.menu-item a {
  font-size: 12px !important;
  color: #565552 !important;
  -webkit-transition: all 150ms ease-in-out !important;
  -moz-transition: all 150ms ease-in-out !important;
  -ms-transition: all 150ms ease-in-out !important;
  -o-transition: all 150ms ease-in-out !important;
  transition: all 150ms ease-in-out !important;
}
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu li.page_item a:hover,
.ted--pre-footer #pre-footer-menu-ted ul.sub-menu li.menu-item a:hover, .ted--pre-footer #pre-footer-menu-ted ul.children li.page_item a:hover,
.ted--pre-footer #pre-footer-menu-ted ul.children li.menu-item a:hover, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu li.page_item a:hover,
.ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.sub-menu li.menu-item a:hover, .ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children li.page_item a:hover,
.ted--pre-footer #pre-footer-menu-ted ul:not(.sub-menu, .children) ul.children li.menu-item a:hover {
  color: #222120 !important;
}
.ted--pre-footer .creative-commons {
  border-top: 2px solid #A8A9A1 !important;
  margin-top: 15px !important;
  padding-top: 20px !important;
}
.ted--pre-footer .creative-commons h6 {
  font-size: 13px !important;
  font-weight: bold !important;
  color: #565552 !important;
  margin-bottom: 3px !important;
}
.ted--pre-footer .creative-commons p {
  color: #A8A9A1 !important;
  font-size: 13px !important;
}
.ted--pre-footer .creative-commons .creative-commons--license {
  margin-left: 15px !important;
}
@media screen and (min-width: 75em) {
  .ted--pre-footer .creative-commons .creative-commons--license {
    margin-left: 0 !important;
  }
}
.ted--pre-footer .creative-commons .creative-commons--license img {
  margin-top: 3px !important;
}

.home #app .content-wrapper .et-boc .et_builder_outer_content .et-l--post .et_builder_inner_content .estantes-section .et_pb_row .et_pb_column .et_pb_module .et_pb_module_inner div .big-post-highlight .flickity-viewport .flickity-slider .post-card header {
  width: 100% !important;
}
.home .et_pb_row {
  padding: 25px 0 !important;
  border-top: 0 !important;
}
.home .big-post-highlight {
  margin-bottom: 40px !important;
}
.home .et_pb_column_1_4 .small-post-highlight .post-card {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
.home .et_pb_column_1_4 .small-post-highlight .post-card .post-title {
  font-size: 14px !important;
}
.home .et_pb_column_1_4 .small-post-highlight .post-card .post-author {
  font-size: 14px !important;
  color: #403f3c !important;
}
.home .et_pb_column_3_4 .small-post-highlight {
  margin-bottom: 0 !important;
  padding-bottom: 20px !important;
}
.home .et_pb_column_3_4 .small-post-highlight .post-card {
  height: auto !important;
}
.home .estantes-section {
  margin-bottom: 0 !important;
}
.home .highlight-multimidia,
.home .highlight-cadernos {
  padding-bottom: 0 !important;
}
.home .colunistas-highlight .item + .item {
  border-top: 0 !important;
}

.highlight-cadernos .highlight-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media print, screen and (max-width: 39.99875em) {
  .highlight-cadernos .highlight-title {
    justify-content: center;
  }
}
.highlight-cadernos .highlight-title .logo {
  background-image: url(/wp-content/themes/teoriaedebate/assets/img/logo-cadernos-ted-color.svg);
  background-position: left center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 320px;
  z-index: 0;
  margin-left: 15px;
  height: 100px;
  text-indent: -500px;
}
.highlight-cadernos .highlight-title span:nth-child(2) {
  font-size: 12px;
  color: #666;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ccc;
  text-transform: uppercase;
}

.big-post-highlight .flickity-prev-next-button {
  background: transparent !important;
}

.post-card .post-image {
  background-color: #f2ebec;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: initial;
  position: relative;
}

.post-card .post-description {
  color: #2a2426;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}

.post-card .post-authors {
  color: #948f8f;
  font-size: 12px;
  margin: 4px 0 0;
}

.post-card .post-image-credit {
  color: #948f8f;
  font-size: 12px;
  margin: 4px 0 0;
}

#app .et_pb_section_0 .et_pb_column_4_4 .flickity-viewport {
  width: 100%;
}

.editorial-header {
  color: white !important;
  text-transform: uppercase !important;
  margin-bottom: 20px !important;
  padding: 20px !important;
  background: url(/wp-content/themes/teoriaedebate/assets/img/barra_secao_editorias.png) !important;
}
.editorial-header h1 {
  margin-bottom: 0 !important;
}

.single-cadernos .post-content > .column.large-12 > p, .single-cadernos .post-content > .large-12.columns > p, .single-cadernos .home .big-post-highlight .post-card .post-content > .large-12.post-image > p, .home .big-post-highlight .post-card .single-cadernos .post-content > .large-12.post-image > p, .single-cadernos .home .big-post-highlight .post-card .post-content > .large-12.post-info > p, .home .big-post-highlight .post-card .single-cadernos .post-content > .large-12.post-info > p, .single-cadernos .et_pb_column_1_4 .small-post-highlight .post-content > .column.post-card > p, .et_pb_column_1_4 .small-post-highlight .single-cadernos .post-content > .column.post-card > p, .single-cadernos .et_pb_column_1_4 .small-post-highlight .post-content > .columns.post-card > p, .et_pb_column_1_4 .small-post-highlight .single-cadernos .post-content > .columns.post-card > p, .single-cadernos .home .big-post-highlight .post-card .et_pb_column_1_4 .small-post-highlight .post-content > .post-image.post-card > p, .home .big-post-highlight .post-card .et_pb_column_1_4 .small-post-highlight .single-cadernos .post-content > .post-image.post-card > p, .single-cadernos .et_pb_column_1_4 .small-post-highlight .home .big-post-highlight .post-card .post-content > .post-image.post-card > p, .et_pb_column_1_4 .small-post-highlight .home .big-post-highlight .post-card .single-cadernos .post-content > .post-image.post-card > p, .single-cadernos .home .big-post-highlight .post-card .et_pb_column_1_4 .small-post-highlight .post-content > .post-info.post-card > p, .home .big-post-highlight .post-card .et_pb_column_1_4 .small-post-highlight .single-cadernos .post-content > .post-info.post-card > p, .single-cadernos .et_pb_column_1_4 .small-post-highlight .home .big-post-highlight .post-card .post-content > .post-info.post-card > p, .et_pb_column_1_4 .small-post-highlight .home .big-post-highlight .post-card .single-cadernos .post-content > .post-info.post-card > p {
  margin: 14px 14vw 0;
  font-size: 1rem;
}
.single-cadernos .post-date {
  display: flex;
  column-gap: 20px;
}
@media print, screen and (max-width: 39.99875em) {
  .single-cadernos .post-date {
    flex-direction: column;
    row-gap: 30px;
  }
  .single-cadernos .post-date img {
    width: 100% !important;
    height: auto;
  }
}
.single-cadernos .post-date .info-caderno {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #948f8f !important;
  font-size: 16px !important;
  margin: 0 0 20px !important;
}

.single-debate .editorial-header {
  background: url(/wp-content/themes/teoriaedebate/assets/img/topo-teoriaedebate-debate.png) !important;
}

.single-estante .editorial-header {
  background: url(/wp-content/themes/teoriaedebate/assets/img/barra_estante.png) !important;
}
.single-estante img {
  max-width: 300px !important;
  height: auto !important;
}
.single-estante img.alignleft {
  float: left !important;
  margin: 0 20px 20px 0 !important;
}
.single-estante img.alignright {
  float: left !important;
  margin: 20px 0 0 20px !important;
}

.single .menu-editorias-container {
  border-bottom: 0px !important;
}
.single .wp-caption {
  margin: 0px 15px 5px 0 !important;
  padding: 0px 0px 10px 0px !important;
}
.single .wp-caption p {
  padding: 0px 10px !important;
  margin: 0px !important;
  color: #494a46 !important;
  font-size: 14px !important;
  line-height: 14px !important;
}
.single .wp-caption.alignleft {
  float: left !important;
}
.single .wp-caption.alignright {
  float: right !important;
  margin-left: 15px !important;
}
.single ul.tags {
  margin: 0 15px 30px !important;
}
.single ul.tags li a {
  color: #BA2131 !important;
  font-size: 13px !important;
  text-transform: uppercase !important;
}
.single .content-wrapper {
  background: #ffffff !important;
}

.page-colunistas .small-post-highlight .post-card {
  height: auto !important;
}

.page .content-area {
  color: #403f3c !important;
}
.page .content-area img {
  max-width: 100% !important;
  height: auto !important;
}
.page .component-tabs-title {
  margin-top: 20px !important;
}
.page .component-tabs-title h2 {
  font-size: 40px !important;
  font-weight: normal !important;
}

.post-header-after .post-date, .post-content .post-date {
  margin-bottom: 10px !important;
}
.post-header-after .social-links, .post-content .social-links {
  margin-bottom: 10px !important;
}
.post-header-after .main-excerpt, .post-content .main-excerpt {
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

.tab-content img {
  max-width: 100% !important;
  height: auto !important;
}

.single .post-content {
  margin-bottom: 40px !important;
  padding: 0 15px !important;
}
.single .post-date.title {
  font-size: 18px !important;
  margin-bottom: 0 !important;
  padding-top: 20px !important;
}
.single .et_pb_button {
  background-color: red;
  padding: 10px;
  border-radius: 10px;
  color: white !important;
}
.single .et_pb_button a {
  bottom: 0;
  top: 10px;
  position: relative;
}

.post-type-archive-debate .post-card .post-category {
  visibility: hidden !important;
}

.post-type-archive-estante .ted-estante-livros .post-info {
  float: right !important;
  width: calc(100% - 130px) !important;
}

.term-cadernos .post-card {
  max-width: 20% !important;
}
@media print, screen and (max-width: 39.99875em) {
  .term-cadernos .post-card {
    max-width: 50% !important;
  }
}
.term-cadernos .post-card.editoria-cadernos-teoria-e-debate .post-image {
  min-height: 325px;
}
@media print, screen and (max-width: 39.99875em) {
  .term-cadernos .post-card.editoria-cadernos-teoria-e-debate .post-image {
    min-height: 225px;
  }
}
.term-cadernos .post-card.editoria-cadernos-teoria-e-debate .post-description {
  display: none;
}

main.tag {
  box-sizing: border-box !important;
  margin: auto !important;
  padding: 20px !important;
}
main.tag .tag--title {
  font-weight: 600 !important;
}
main.tag .tag--details {
  color: #403f3c !important;
}
main.tag .tag--details strong {
  color: #903743 !important;
}
@media (max-width: 767px) {
  main.tag .tag--pagination {
    padding: 10px 0 !important;
  }
}
@media (min-width: 768px) {
  main.tag .tag--pagination {
    float: right !important;
  }
}
main.tag .tag--pagination-button {
  background-color: #a7a8a0 !important;
  border-radius: 3px !important;
  color: #403f3c !important;
  display: inline-block !important;
  padding: 3px 13px !important;
}