//===============================================
// Global's styles
//===============================================

@font-face {
	font-family: 'Lucida Sans'!important;
	src: url('/wp-content/themes/teoriaedebate/resources/scss/base/lucida_sans_regular-webfont.woff2') format('woff2'),
	url('/wp-content/themes/teoriaedebate/resources/scss/base/lucida_sans_regular-webfont.woff') format('woff')!important;
	font-weight: normal!important;
	font-style: normal!important;
}

$body-font-family: 'Lucida Sans', sans-serif!important;
$header-font-family: $body-font-family!important;
$subheader-font-family: $body-font-family!important;

body.et-pb-theme-teoria #app * {
	font-family: $body-font-family;
}
body.et-pb-theme-teoria #app .category,
body.et-pb-theme-teoria #app .post-category {
	font-size: 12px;
    text-transform: uppercase;
}
body.et-pb-theme-teoria #app .category,
body.et-pb-theme-teoria #app .post-category {
	font-size: 12px;
    text-transform: uppercase;
}
.post-card .post-category {
    margin: 10px 0 2px;
}
.post-card .post-title {
    border-bottom: 1px solid transparent;
    display: inline;
    font-size: 24px !important;
    font-weight: 300;
    line-height: 1.2;
    transition: border .3s ease;
}
body.single-colunas .et_pb_column.et_pb_column_1_4 .post-title {
	font-weight: 300 !important;
	font-size: 28px !important;
}
body.single-colunas .et_pb_column.et_pb_column_1_4 .post-card,
.post-card {
	margin-bottom:15px;
}
.highlight-title {
	font-weight: 500!important;
	text-transform: lowercase!important;
	line-height: 1!important;
	margin-bottom: 15px!important;
}

.no-p {
	padding: 0!important;
}




//Cores das editorias

$editorias: (
	"economia": #81a9a8,
	"cultura": #ef902d,
	"internacional": #173426,
	"mundo-do-trabalho": #4d8376,
	"nacional" :#777b2d,
	"politica" :#484a1c,
	"sociedade" :#214433
	);

@each $editoria, $color in $editorias {
	.editoria-#{$editoria} {
		.post-category, .category{
			color: $color!important;
		}
	}

	.menu .menu-item-#{$editoria} {
		&:hover a, &.current-menu-item a{
			background-color: $color!important;
		}
	}
}
// .editoria-economia{
// 	.post-category{
// 		color: #81a9a8!important;
// 	}
// }

// .editoria-cultura{
// 	.post-category{
// 		color: #ef902d!important;
// 	}
// }

// .editoria-internacional{
// 	.post-category{
// 		color: #173426!important;
// 	}
// }

// .editoria-mundo-do-trabalho{
// 	.post-category{
// 		color: #4d8376!important;
// 	}
// }

// .editoria-nacional{
// 	.post-category{
// 		color: #777b2d!important;
// 	}
// }

// .editoria-politica{
// 	.post-category{
// 		color: #484a1c!important;
// 	}
// }

// .editoria-sociedade{
// 	.post-category{
// 		color: #214433!important;
// 	}
// }

[style='background-image: url("undefined")!important;']{
	display: none!important;
}


.content-wrapper {
    // background: linear-gradient(to bottom, rgba($light-gray,0.5) 0%, rgba($light-gray,0.4) 5%, $white 570px)!important;
    background: linear-gradient(to bottom, #DCDCD2 0, #DCDCD2 100px, #FFFFFF 900px)!important;
}


.fa {
	font-family: FontAwesome !important;
}

.ted--pre-footer .edicao .logo-ted span {
    position: absolute!important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
}
body:not(.home) a.btn-small.load-more {
	margin-bottom:20px;
}
body.tax-tipodemultimdia .sidebar ul li a {
	text-transform:inherit !important;
}

// Ajustes em multimidia

.multimidia-template-default .flickity-viewport{
	height: 600px;
}

.multimidia-template-default .multimidia-item{
	height:90%;
}

.multimidia-template-default .multimidia-item img{
	height:90%;
}

.multimidia-template-default .flickity-slider{
left: 150px !important;
}

.multimidia-template-default .flickity-page-dots{
	padding-bottom:30px;
}
