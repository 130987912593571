.archive {
    .sidebar.hide + .column {
        .post-card {
            @extend .large-3;
        }
    }
}


.post-type-archive-debate{
	.post-card .post-category {
		visibility: hidden!important;
	}
}

.post-type-archive-estante .ted-estante-livros .post-info{
    float: right!important;
    width: calc(100% - 130px)!important;
}


.term-cadernos .post-card{
    max-width: 20%!important;
    @include breakpoint(small down) {
        max-width: 50%!important;
    }
    &.editoria-cadernos-teoria-e-debate{
        .post-image{
            min-height: 325px;

            @include breakpoint(small down) {
                min-height: 225px;
            }
        }
        .post-description{display: none;}
    }

}