.big-post-highlight {
    .post-card {
        @extend .smaller-12;
        margin-bottom: 20px!important;
        .post-image {
            height: 40vmax!important;
            @include breakpoint(large) {
                height: 530px!important;
            }
        }

        .post-title {
            font-size: 24px!important;
        }
    }
}

.small-post-highlight {
    .post-card {
        @extend .smaller-12;
        @extend .medium-6;
        @extend .large-4;
        margin-bottom: 20px!important;
        @include breakpoint(large) {
            height: 330px!important;
        }
        .post-image {
            height: 20vmax!important;
            @include breakpoint(large) {
                height: 160px!important;
            }
        }
    }
}