.opinions.post-card{
    p{ display:none!important; }

    .title{
        color: #403F3C!important; 
        font-size: 21px!important;
    }
}

.opinions-text{
    .title{
        font-size: 24px!important;
        color: #903743!important;
        font-weight: bold!important;
    }
    .title + p { display:none!important; }
    .post-authors:not(.chamada) + p { display:none!important; }
}

.opinions{
    .title, .chamada, .post-authors {
        cursor: pointer!important;
    }

    &.opinions-text{
        display: none;
    }

    &:not(.opinions-text) .text{
        max-height: 0px!important;
        overflow: hidden!important;
        transition: all .6s ease-in-out!important; 
        
        &.active{
            max-height: 4000px!important;
        }
    }
}

.opinioes{
    h3{
        color: black!important;
        margin-bottom: 15px!important;
    }

    .text,.title{
        margin-bottom: 5px!important;
    }
    
    .text{
        color: #505050!important;
    }

    .author{
        font-size: 14px!important;
    }
}