main.tag {
    box-sizing: border-box!important;
    margin: auto!important;
    padding: 20px!important;

    .tag--title {
        font-weight: 600!important;
    }

    .tag--details {
        color: $dark-gray!important;

        strong {
            color: $dark-red!important;
        }
    }

    .tag--pagination {
        @media (max-width: 767px) {
            padding: 10px 0!important;
        }

        @media (min-width: 768px) {
            float: right!important;
        }

        &-button {
            background-color: $light-gray!important;
            border-radius: 3px!important;
            color: $dark-gray!important;
            display: inline-block!important;
            padding: 3px 13px!important;
        }
    }
}
