//===============================================
// Icons map
//===============================================

$icons: (
    envelope: '\f003',
    facebook: '\f09a',
    map-marker: '\f041',
    play: '\f04b',
    search: '\f002',
    twitter: '\f099',
    youtube: '\f16a'
);

//===============================================
// Icons mixin
//===============================================

// For adding font icons to elements using CSS pseudo-elements
// http://jaydenseric.com/blog/fun-with-sass-and-font-icons
@mixin icon($position: before, $icon: false, $styles: true) {
    @if $position == both {
        $position: 'before, &:after';
    }
    // Either a :before or :after pseudo-element, or both, defaulting to :before
    &:#{$position} {
        @if $icon {
            // A particular icon has been specified
            content: "#{map-get($icons, $icon)}";
        }
        @if $styles {
            // Supportive icon styles required
            font-style: normal!important;
            font-weight: normal!important;
            font-family: $icon-font-family!important;
        }
        // Include any extra rules supplied for the pseudo-element
        @content;
    }
}

//===============================================
// Links mixin
//===============================================

@mixin link-underline($color,$hover-color,$border-color) {
    color: $color!important;
    border-bottom: 1px solid transparent!important;
    transition: all 0.3s ease!important;
    &:focus, &:active {
        color: $hover-color!important;
        border-bottom: 1px solid $border-color!important;
    }
    @include breakpoint(xlarge) {
        &:hover, &:focus, &:active {
            color: $hover-color!important;
            border-bottom: 1px solid $border-color!important;
        }
    }
}

//===============================================
// Helper's styles
//===============================================

.no-p {
    padding: 0px!important;
}

.sidebar {
    ul {
        margin: 0!important;
        padding: 10px!important;
        list-style: none!important;
        li {
            &:before { 
                content: ''!important;
                border-color: transparent $medium-gray!important;
                border-style: solid!important;
                border-width: 0.35em 0 0.35em 0.45em!important;
                display: block!important;
                height: 0!important;
                width: 0!important;
                left: -1em!important;
                top: 24px!important;
                position: relative!important;
            }
            a { 
                padding: 10px!important;
                font-size: 21px!important;
                text-transform: lowercase!important;
                &:hover, &:active, &:focus {
                    color: $medium-red!important;
                }
            }
            &:hover, &:active, &:focus {
                color: $medium-red!important;
                &:before { 
                    border-color: transparent $medium-red!important;
                }
            }
        }
    }
}

.right{ float: right }
.left{ float: left }