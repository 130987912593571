
.editorial-header{
	color: white!important;
	text-transform: uppercase!important;
	margin-bottom: 20px!important;
	padding: 20px!important;
	background: url(/wp-content/themes/teoriaedebate/assets/img/barra_secao_editorias.png)!important;

	h1{
		margin-bottom: 0!important;
	}
}
.single-cadernos{
	.post-content > .column.large-12 > p{margin: 14px 14vw 0; font-size: 1rem;}
	.post-date{
		display: flex;
		column-gap: 20px;
		@include breakpoint(small down) {
			flex-direction: column;
			row-gap: 30px;
			img{
				width: 100%!important;
    			height: auto;
			}
		}
		.info-caderno{
			display: flex;
			flex-direction: column;
			justify-content: center;
			color: #948f8f !important;
			font-size: 16px !important;
			margin: 0 0 20px !important;
		}
	}
}
.single-debate{
    .editorial-header{
        background: url(/wp-content/themes/teoriaedebate/assets/img/topo-teoriaedebate-debate.png)!important;
    }
}

.single-estante{
	.editorial-header{
		background: url(/wp-content/themes/teoriaedebate/assets/img/barra_estante.png)!important;
	}


    img {
        max-width: 300px!important;
        height: auto!important;
        &.alignleft {
            float: left!important;
            margin: 0 20px 20px 0!important;
        }
        &.alignright {
            float: left!important;
            margin: 20px 0 0 20px!important;
        }
    }
}

.single {
	.menu-editorias-container {
		border-bottom: 0px!important;
	}

	.wp-caption {
		margin: 0px 15px 5px 0!important;
		padding: 0px 0px 10px 0px!important;

		p {
			padding: 0px 10px!important;
			margin: 0px!important;
			color: #494a46!important;
			font-size: 14px!important;
			line-height: 14px!important;
		}

		&.alignleft {
			float: left!important;
		}

		&.alignright {
			float: right!important;
			margin-left: 15px!important;
		}
	}

	ul.tags {
		margin: 0 15px 30px!important;
		li {
			a {
				color: #BA2131!important;
				font-size: 13px!important;
				text-transform: uppercase!important;
			}
		}
	}

    .content-wrapper {
        background: #ffffff!important;
    }
}


.page-colunistas .small-post-highlight .post-card {
	height: auto!important;
}

.page {
	.content-area {
		color: $dark-gray!important;

        img {
            max-width: 100%!important;
            height: auto!important;
        }
	}
	.component-tabs-title {
		margin-top: 20px!important;
		h2 {
			font-size: 40px!important;
			font-weight: normal!important;
		}
	}

}

.post-header-after, .post-content {
    .post-date{
        margin-bottom: 10px!important;
    }

    .social-links{
        margin-bottom: 10px!important;
    }

    .main-excerpt {
        font-size: 20px!important;
        margin-bottom: 15px!important;
    }
}

.tab-content {
    img {
        max-width: 100%!important;
        height: auto!important;
    }
}

.single {
    .post-content {
	    margin-bottom: 40px!important;
	    padding: 0 15px!important;
    }

    .post-date.title {
        font-size: 18px!important;
        margin-bottom: 0!important;
        padding-top: 20px!important;
    }

	.et_pb_button{
		background-color: red;
		padding: 10px;
		border-radius: 10px;
		color: white !important;
		a{
			bottom: 0;
			top: 10px;
    		position: relative;
		}
	}
}
