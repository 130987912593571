.post-small-list {
    ul li {
        border-left: none!important;
    }
    .number {
        font-size: 40px!important;
        padding-left: 4px!important;
    }
    .category, .title {
        margin: 0 0 5px!important;
    }
    & + .post-small-list {
        margin-top: 30px!important;
    }
}

.post-card .post-image-credit{
    color: #948f8f!important;
    font-size: 12px!important;
    margin: 4px 0 0!important;
}