.highlight-emdebate, .highlight-teoriaedebate {
	.header {
		background: #FFFFFF!important;
	}
	&:after {
		background: #FFFFFF!important;
	}
}

.opinioes.item{
    p.title { 
        color: #c80c2f!important; 
        font-size: 24px!important;
    }
}