.post-content {
    p > a{text-decoration: underline; color: #BA2131;}

    .post-share {
        border-top: 0!important;
        margin: 0!important;
        padding-top: 0!important;
		margin-top: 10px !important;
        .social-links {
            a {
                color: $medium-gray!important;
                font-size: 1.2rem!important;
                transition: color 0.3s ease!important;
                &:before {
                    color: $medium-gray!important;
                }
                &:active, &:focus {
                    color: $dark-gray!important;
                    &:before {
                        color: $dark-gray!important;
                    }
                }
                span {
                    @include element-invisible;
                }
            }
        }
    }
}
