.estantes-section {
	background-color: $white!important;
	// background: linear-gradient(to right, $white 0%, rgba($light-gray, 0.5) 50%, $white 100%)!important;
	background: linear-gradient(to right, #FFFFFF 0%, #DCDCD2 50%, #FFFFFF 100%)!important;
	margin-bottom: 30px!important;
}

.estante-highlight:before {
    display: none!important;
}

.estante-highlight .img {
    float: left!important;
}
