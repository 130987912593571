//===============================================
// Main header
//===============================================

.main-header {
    background: $light-gray!important;
    // background: linear-gradient(to bottom, $white 0%, rgba($light-gray,0.5) 100%)!important;
    background: linear-gradient(to bottom, #FFFFFF 0px, #DCDCD2 70%)!important;
    margin-bottom: 0!important;
    @include breakpoint(medium down) {
        padding-bottom: 30px!important;
    }

    &:after {
        content: ''!important;
        background: url(/wp-content/themes/perseuabramo/assets/img/FPA_grafismo_topo.png)!important;
        width: 250px!important;
        height: 107px!important;
        position: absolute!important;
        background-repeat: no-repeat!important;
        background-size: 129px!important;
        left: -1px!important;
        top: -15px!important;
        @include breakpoint(medium down) {
            display: none!important;
        }
    }

    .logo-link {
        display: block!important;
        margin-bottom: -5px!important;
    }

    .logo-fpa {
        background-image: url(/wp-content/themes/teoriaedebate/assets/img/logo-td.png)!important;
        background-repeat: no-repeat!important;
        height: 45px!important;
        width: 275px!important;
        margin-bottom: 0!important;

        @include breakpoint(small down) {
            height: 35px!important;
            background-size: auto 100%!important;
        }
        @include breakpoint(large xlarge) {
            margin-left: 70px!important;
        }
        @media screen and (min-width: 1400px) {
            margin-left: 0!important;
        }

    }

    .edicao {
        color: $dark-gray!important;
        font-size: 13px!important;
        @include breakpoint(small down) {
            margin-top: 10px!important;
        }
        a {
            color: $light-gray!important;
            border-bottom: 1px solid transparent!important;
            transition: all 0.3s ease!important;
            @include link-underline($light-gray,$light-gray,$light-gray);
        }
        p {
            margin-bottom: 0!important;
            & + p {
                margin-top: 5px!important;
            }
        }
    }
}

.menu-editorias-container {
    border-top: 1px solid white!important;
    border-bottom: 1px solid white!important;
    @include breakpoint(large) {
        background-color: transparent!important;
    }
    .search-input {
        background-color: transparent!important;
    }
}

.menu-editorias {
    .menu-item {
        a {
            font-weight: 300!important;
        }
    }
}

.header-logo {
    @include breakpoint(small down) {
        .social-links {
            margin: 0 15px!important;
        }
    }
}

.search-container {
    right: 0!important;
    @include breakpoint(medium) {
        // bottom: -20px!important;
    }

    @include breakpoint(large) {
        bottom: 0!important;
        .search-input {
            bottom: 9px!important;
        }
        label[for="search-input-check"] {
            bottom: 7px!important;
        }
    }
}
