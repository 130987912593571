h3#comments {
    @include breakpoint(medium down) {
        margin: 30px 20px 0 20px!important;
    }
    margin-top: 50px!important;
    width:100%!important;
}


ol.commentlist {
    @extend .smaller-12;
    @extend .medium-6;
    @extend .large-9;
    list-style:none!important;
    margin:20px 0!important;
    text-indent:0!important;
    padding: 0!important;
    li {
        @include breakpoint(medium down) {
            margin: 0 20px!important;
        }
        @include breakpoint(large) {
            margin: 0 30px 0 0!important;
        }
        &.alt { }
        &.bypostauthor {
            & > .comment-body div.vcard cite.fn  {
                color: #903743!important;
            }
        }
        &.byuser {}
        &.comment-author-admin {}
        &.comment {
            // background: gray!important;
            border-top:1px solid #BFC0B9!important;
            padding: 30px 0px 0px 30px!important;
            @include breakpoint(medium down) {
                padding: 15px 0px 0px 15px!important;
            }
            // #903743
            // margin-bottom: 30px!important;
            div.comment-author {}
            div.vcard {
                cite.fn {
                    display: inline-block!important;
                    font-style: normal!important;
                    font-size: 18px!important;
                    font-weight: bold!important;
                    a.url {}
                    &:before {display: none!important;}
                    // &:after {
                    //     content: ""!important;
                    //     display: inline-block!important;
                    //     width: 0!important;
                    //     height: 0!important;
                    //     border-style: solid!important;
                    //     border-width: 6px 0 6px 10px!important;
                    //     border-color: transparent transparent transparent #903743!important;
                    // }
                }
                img {
                    &.avatar {
                        border:1px solid #ccc!important;
                        float:left!important;
                        height: 50px!important;
                        width: 50px!important;
                        border-radius: 50%!important;
                        margin: -1px 15px 0 0!important;
                    }
                    &.avatar-32 {}
                    &.photo {}
                }
                span.says {
                    font-size: 12px!important;
                    display: inline-block!important;
                }
            }
            div.commentmetadata {}
            div.comment-meta {
                a {
                    font-size: 12px!important;
                    color: #BFC0B9!important;
                }
            }
            p {
                font-size: 14px!important;
                margin:15px 0!important;
                padding-right: 30px!important;
                @include breakpoint(medium down) {
                    padding-right: 15px!important;
                }
            }
            ul {
                list-style:square!important;
                margin:0 0 1em 2em!important;
            }
            div.reply {
                padding-bottom: 30px!important;
                @include breakpoint(medium down) {
                    padding-bottom: 15px!important;
                }
                a {
                    font-size: 13px!important;
                    font-weight:bold!important;
                }
            }
            ul.children {
                list-style:none!important;
                margin: 0!important;
                text-indent:0!important;
                li {
                    &.alt {}
                    &.bypostauthor {}
                    &.byuser {}
                    &.comment {}
                    &.comment-author-admin {}
                    &.depth-2 {
                        // border-left:5px solid #555!important;
                        margin:0 0 30px 0px!important;
                        @include breakpoint(medium down) {
                            margin: 0 0px 15px 0!important;
                        }
                    }
                    &.depth-3 {
                        // border-left:5px solid #999!important;
                        margin:0 0 30px 0px!important;
                        @include breakpoint(medium down) {
                            margin: 0 0px 15px 0!important;
                        }
                    }
                    &.depth-4 {
                        // border-left:5px solid #bbb!important;
                        margin:0 0 30px 0px!important;
                        @include breakpoint(medium down) {
                            margin: 0 0px 15px 0!important;
                        }
                    }
                    &.depth-5 {
                        margin: 0!important;
                        padding-bottom: 30px!important;
                        @include breakpoint(medium down) {
                            padding-bottom: 15px!important;
                        }
                        p {margin-bottom: 0!important;}
                    }
                    &.odd {}
                }
            }
        }
        &.even {  }
        &.odd {  }
        &.parent {
            // border-left:1px dotted #111!important;
            border-left:1px dotted rgba(#111,.1)!important;
            transition: all 200ms ease-in-out!important;
            &:hover {
                border-left:1px dotted rgba(#111,.4)!important;
            }
        }
        &.thread-alt { }
        &.thread-even {}
        &.thread-odd {}
    }
}

#respond {
    @extend .smaller-12;
    @extend .medium-6;
    @extend .large-3;
    margin: 20px 0 50px 0;

    @include breakpoint(large) {
        margin-top: 10px!important;
    }

    @include breakpoint(medium down) {
        h3,form {padding: 0 15px!important;}
    }
    .submit {
        border-radius: 5px!important;
        color: white!important;
        background: #d41f29!important;
        border: 0!important;
        font-family: "Roboto Condensed", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif!important;
        font-size: 12px!important;
        cursor: pointer!important;
        padding: 6px 10px 5px 10px!important;
        text-transform: uppercase!important;
        -webkit-transition: all 0.3s ease!important;
        transition: all 0.3s ease!important;
        &:hover {
            background: darken(#d41f29,8%)!important;
        }
    }
}
